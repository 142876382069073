import { Button, H2 } from '@appliedsystems/applied-design-system';
import React from 'react';
import { usePaymentsTranslation } from '../../hooks/usePaymentsTranslation';
import { useAccountManagementStore } from '../../store/AccountManagement';
import classes from './AdyenPaymentContainer.module.scss';

type Props = {
  isAccountManagementEnabled?: boolean | null;
  isPaymentSuccess?: boolean;
  isUserLoggedIn?: boolean;
};

export const AdyenPaymentContainer = ({
  isAccountManagementEnabled,
  isPaymentSuccess,
  isUserLoggedIn
}: Props) => {
  const { t } = usePaymentsTranslation();
  const { loginModal, customerUser } = useAccountManagementStore();

  return (
    <>
      <div className="mb-100">
        <H2 className={classes.paymentMethodTitle}>{t('PAYMENT_METHOD')}</H2>
      </div>

      <div className={classes.form}>
        {isAccountManagementEnabled && !isPaymentSuccess && !isUserLoggedIn && (
          <Button
            className={classes.paymentMethodOnFileButton}
            onClick={!customerUser ? loginModal.open : undefined}
          >
            {t('USE_PAYMENT_METHOD_ON_FILE')}
          </Button>
        )}

        <div id="dropin-container"></div>
      </div>
    </>
  );
};
