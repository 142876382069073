import { Alert, ModalBody } from '@appliedsystems/applied-design-system';
import { ErrorCode, LocaleCode } from '@appliedsystems/payments-core';
import React, { useEffect, useRef, useState } from 'react';
import { Locale } from '../../store/Locale';
import { getConfig } from '../../util/config';
import { getLanguageCode } from '../../util/util';

const recaptchaSiteKey = getConfig('REACT_APP_CHECKBOX_RECAPTCHA_SITE_KEY');

let recaptchaScript: HTMLScriptElement | undefined; // loaded recaptcha script

const loadRecaptchaScript = (
  localeCode: LocaleCode,
  onRecaptchaReady: () => void,
  retryCount = 3
) => {
  if (!recaptchaSiteKey)
    throw new Error('Checkbox Recaptcha site key not found');

  if (recaptchaScript) {
    onRecaptchaReady();
    return;
  }

  const script = document.createElement('script');
  const languageCode = getLanguageCode(localeCode);
  script.src = `https://www.google.com/recaptcha/enterprise.js?render=explicit&hl=${languageCode}`;
  script.async = true;
  script.onerror = () => {
    console.error('Failed to load recaptcha script');
    document.body.removeChild(script); // Remove the failed script
    if (retryCount > 0) {
      loadRecaptchaScript(localeCode, onRecaptchaReady, retryCount - 1); // Retry loading the script
    }
  };
  script.onload = () => {
    if (!window.grecaptcha) {
      // script is loaded but recaptcha object is not found
      console.error('Could not find recaptcha object');
      return;
    }
    window.grecaptcha.enterprise.ready(() => {
      recaptchaScript = script;
      onRecaptchaReady();
    });
  };

  document.body.appendChild(script);
};

export const CheckboxRecaptcha = ({
  onError,
  onSuccess,
  recaptchaAlertOpen,
  action
}: {
  onError: (errorCode: ErrorCode) => void;
  onSuccess: (recaptchaToken: string) => Promise<void>;
  recaptchaAlertOpen: boolean;
  action: string;
}) => {
  const { locale } = Locale.useContainer();
  const [isRecaptchaReady, setIsRecaptchaReady] = useState(false);
  const recaptchaContainerRef = useRef<HTMLDivElement>(null);
  const [isRecaptchaRendered, setIsRecaptchaRendered] = useState(false);
  const isMounted = useRef(true);

  useEffect(() => {
    if (isRecaptchaReady) return;
    loadRecaptchaScript(locale, () => setIsRecaptchaReady(true));
  }, [isRecaptchaReady, locale]);

  useEffect(() => {
    isMounted.current = true;

    const renderRecaptcha = () => {
      const recaptchaProvider = window.grecaptcha?.enterprise;
      if (
        !isRecaptchaReady ||
        !recaptchaProvider ||
        !recaptchaContainerRef.current
      ) {
        console.error(
          `Recaptcha is not ready- ready? ${isRecaptchaReady}, provider? ${!!recaptchaProvider}, grecaptcha? ${!!window.grecaptcha}, key? ${!!recaptchaSiteKey}, container? ${!!recaptchaContainerRef.current},container visible? ${
            recaptchaContainerRef.current?.offsetParent !== null
          }, action? ${action}`
        );
        onError(ErrorCode.RecaptchaVerificationFailed);
        return;
      }
      try {
        const recaptchaId = recaptchaProvider.render(
          recaptchaContainerRef.current,
          {
            sitekey: recaptchaSiteKey,
            action,
            callback: async (recaptchaToken: string) => {
              await onSuccess(recaptchaToken);
              recaptchaProvider.reset(recaptchaId);
              if (isMounted.current) setIsRecaptchaRendered(false);
            }
          }
        );

        setIsRecaptchaRendered(true);
      } catch (err) {
        console.error('Error rendering recaptcha', err);
        if (isMounted.current) onError(ErrorCode.RecaptchaVerificationFailed);
      }
    };

    if (recaptchaAlertOpen && !isRecaptchaRendered) renderRecaptcha();

    return () => {
      isMounted.current = false;
    };
  }, [
    action,
    isRecaptchaReady,
    isRecaptchaRendered,
    onError,
    onSuccess,
    recaptchaAlertOpen
  ]);

  return (
    <>
      <Alert
        open={(recaptchaAlertOpen || isRecaptchaRendered) && isRecaptchaReady}
      >
        <ModalBody>
          <div ref={recaptchaContainerRef}></div>
        </ModalBody>
      </Alert>
    </>
  );
};
