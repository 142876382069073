import {
  BrandType,
  Button,
  Form,
  InlineAlert,
  ModalBody,
  ModalFooter,
  object,
  string
} from '@appliedsystems/applied-design-system';
import { ErrorCode } from '@appliedsystems/payments-core';
import React, { useCallback, useState } from 'react';
import { usePaymentsTranslation } from '../../../hooks/usePaymentsTranslation';
import { Translation } from '../../../localization/translations';
import styles from '../AccountManagementModals.module.scss';
import { VerificationCodeField } from './VerificationCodeField';

export const makeEnterCodeSchema = (t: (key: keyof Translation) => string) =>
  object({
    code: string().required(t('VERIFICATION_CODE_REQUIRED'))
  });

type VerificationCodeFormData = {
  code: string;
};

type Props = {
  isCreatingAccount?: boolean;
  onResendCode?: () => void;
  onBackClick?: () => void;
  onSubmit: (code: string) => Promise<void>;
  error?: ErrorCode;
  userEmail?: string;
  onCreateAccountClick?: () => void;
};

export const VerificationCodeForm = ({
  isCreatingAccount,
  onResendCode,
  onBackClick,
  onSubmit,
  error,
  userEmail,
  onCreateAccountClick
}: Props) => {
  const { t } = usePaymentsTranslation();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const schema = React.useMemo(() => makeEnterCodeSchema(t), []);
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (values: VerificationCodeFormData) => {
      setLoading(true);

      await onSubmit(values.code);

      setLoading(false);
    },
    [onSubmit]
  );

  return (
    <Form schema={schema} onSubmit={handleSubmit}>
      <ModalBody>
        <div className={styles.modalBody}>
          <p>
            {!!isCreatingAccount && (
              <>
                {t('YOUR_ACCOUNT_HAS_BEEN_CREATED')}
                <br />
              </>
            )}
            {t('ENTER_VERIFICATION_CODE')}{' '}
            {!!userEmail && (
              <>
                (<b>{userEmail}</b>)
              </>
            )}{' '}
          </p>
          <VerificationCodeField error={error} />

          <Button
            type="link"
            onClick={onResendCode}
            submit={false}
            className="mb-50"
          >
            {t('RESEND_CODE')}
          </Button>

          {!isCreatingAccount && onCreateAccountClick && (
            <InlineAlert type={BrandType.Info} closeable={false}>
              {t('DONT_RECEIVE_VERIFICATION_CODE')}
            </InlineAlert>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button type="tertiary" onClick={onBackClick}>
          {t('BACK')}
        </Button>
        <Button
          type="primary"
          className="ml-100"
          submit
          isLoading={loading}
          testId="loginButton"
        >
          {t('LOG_IN')}
        </Button>
      </ModalFooter>
    </Form>
  );
};
