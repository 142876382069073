export enum HppStep {
  ACCOUNT_INFORMATION = 'ACCOUNT_INFORMATION',
  POLICY_INFORMATION = 'POLICY_INFORMATION',
  PAYMENT_METHOD = 'PAYMENT_METHOD'
}

export enum PayBySelection {
  NONE = 'NONE',
  AMOUNT = 'AMOUNT',
  INVOICE = 'INVOICE'
}

export enum HppFlow {
  HPP = 'HPP',
  CHECKOUT = 'CHECKOUT'
}
