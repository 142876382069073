import {
  BrandType,
  Modal,
  useNotifications
} from '@appliedsystems/applied-design-system';
import { ErrorCode } from '@appliedsystems/payments-core';
import React, { useCallback, useState } from 'react';
import { useCustomerUserRequestVerificationCode } from '../../../hooks/useCustomerUserRequestVerificationCode';
import { usePaymentsTranslation } from '../../../hooks/usePaymentsTranslation';
import { useAccountManagementStore } from '../../../store/AccountManagement';
import { VerificationCodeForm } from '../VerificationCodeForm';
import { LogInForm, LogInFormData } from './LogInForm';

type Props = {
  paymentSessionToken: string;
};

export const LogInModal = ({ paymentSessionToken }: Props) => {
  const { t } = usePaymentsTranslation();
  const { addNotification } = useNotifications();
  const { loginModal, login, createAccountModal } = useAccountManagementStore();
  const [isEnterCodeStep, setIsEnterCodeStep] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const { requestVerificationCode } = useCustomerUserRequestVerificationCode();
  const [verificationCodeError, setVerificationCodeError] =
    useState<ErrorCode>();

  const handleLoginFormSubmit = useCallback(
    async (values: LogInFormData) => {
      setUserEmail(values.email);
      await requestVerificationCode(paymentSessionToken, values.email, () => {
        setIsEnterCodeStep(true);
      });
    },
    [paymentSessionToken, requestVerificationCode]
  );

  const handleCancelClick = useCallback(() => {
    if (isEnterCodeStep) {
      setIsEnterCodeStep(false);
      return;
    }

    loginModal.close();
  }, [isEnterCodeStep, loginModal]);

  const handleVerificationCodeFormSubmit = useCallback(
    async (verificationCode: string) => {
      setVerificationCodeError(undefined);

      try {
        const response = await login(paymentSessionToken, {
          email: userEmail,
          verificationCode
        });

        if (response.status !== 'ok') {
          if (
            response.additionalDetails?.[0]?.errorCode ===
            ErrorCode.VerificationCodeNotValid
          ) {
            setVerificationCodeError(ErrorCode.VerificationCodeNotValid);
            return;
          }

          console.error('Unexpected response status', response);
          throw new Error('Unexpected response status');
        }

        loginModal.close();
        setIsEnterCodeStep(false);
      } catch (error) {
        console.error(
          'Failed to login customer user account with an error',
          error
        );

        addNotification({
          type: BrandType.Error,
          title: t('ERROR'),
          time: '',
          content: t('FAILED_TO_LOGIN')
        });
      }
    },
    [addNotification, login, loginModal, paymentSessionToken, t, userEmail]
  );

  const handleResendCodeClick = async () => {
    await requestVerificationCode(paymentSessionToken, userEmail);
  };

  const handleCreateAccountClick = () => {
    loginModal.close();
    createAccountModal.open();
  };

  return (
    <Modal
      open={loginModal.isOpen}
      title={t('LOG_IN')}
      onClose={() => {
        loginModal.close();
        setIsEnterCodeStep(false);
      }}
    >
      {isEnterCodeStep ? (
        <VerificationCodeForm
          onBackClick={handleCancelClick}
          onSubmit={handleVerificationCodeFormSubmit}
          onResendCode={handleResendCodeClick}
          error={verificationCodeError}
          userEmail={userEmail}
          onCreateAccountClick={handleCreateAccountClick}
        />
      ) : (
        <LogInForm
          onCancel={handleCancelClick}
          onSubmit={handleLoginFormSubmit}
          onCreateAccountClick={handleCreateAccountClick}
        />
      )}
    </Modal>
  );
};
