import { Flex, H1 } from '@appliedsystems/applied-design-system';
import React from 'react';

export const Title = ({
  title,
  description
}: {
  title: string;
  description: string;
}): React.ReactElement => {
  return (
    <Flex className={`flex-d-column`}>
      <H1 className="text-center">{title}</H1>
      <p className={`text-center`}>{description}</p>
    </Flex>
  );
};
