import {
  BrandType,
  Button,
  Col,
  Flex,
  InlineAlert,
  Row
} from '@appliedsystems/applied-design-system';
import { PaymentMethod } from '@appliedsystems/payments-core';
import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { ApiClient } from '../../api/ApiClient';
import { currencyMap } from '../../constants/constants';
import { useAccountManagement } from '../../hooks/useAccountManagement';
import { usePaymentsTranslation } from '../../hooks/usePaymentsTranslation';
import { useAccountManagementStore } from '../../store/AccountManagement';
import { useAgencyDetailsStore } from '../../store/AgencyDetail';
import { Locale } from '../../store/Locale';
import { getAmountWithFees } from '../../util/getAmountWithFees';
import { AcceptCCFeeCheckbox } from '../AcceptCCFeeCheckbox/AcceptCCFeeCheckbox';
import { AdyenWebComponent } from '../AdyenWebComponent/AdyenWebComponent';
import { ErrorAlert, ErrorMessage } from '../ErrorAlert/ErrorAlert';
import { useHppDataStore } from '../HostedPaymentPageContainer/useHppData';
import { PaymentMethodSelection } from '../PaymentMethodSelection/PaymentMethodSelection';
import classes from './PaymentMethodContainer.module.scss';

type Props = {
  onPaymentSuccess: () => void;
  onBack: () => void;
};

export const PaymentMethodContainer = ({
  onPaymentSuccess,
  onBack
}: Props): React.ReactElement => {
  const [isCCFeeAccepted, setIsCCFeeAccepted] = useState(false);
  const { data: agencyDetails } = useAgencyDetailsStore();
  const [errorMessage, setErrorMessage] = useState<ErrorMessage>();
  const { t } = usePaymentsTranslation();
  const { paymentMethodConfig, hppData, setHppData } = useHppDataStore();
  const handleCheckboxChange = (checked: boolean) => {
    setIsCCFeeAccepted(checked);
  };
  const { isCcFeeCheckboxEnabled, ccFeeThresholdAmount } = agencyDetails || {};
  const hppToken = agencyDetails?.token;
  const {
    isAccountManagementEnabled: isAccountManagementFlagEnabled,
    customerUser
  } = useAccountManagement(agencyDetails?.appliedProductId, hppToken);
  const isAccountManagementEnabled =
    isAccountManagementFlagEnabled && agencyDetails?.manageMyAccountEnabled;
  const { loginModal, customerUser: user } = useAccountManagementStore();
  const { locale } = Locale.useContainer();

  const storedPaymentMethods = useQuery(
    ['paymentMethods', customerUser?.id],
    async () => {
      if (!customerUser) return null;
      const response = await ApiClient.getInstance().getStoredPaymentMethods();
      if (response.status !== 'ok' || !response.data) {
        console.error(`Invalid response from server`, response);
        throw new Error(`Invalid response from server`);
      }

      return response.data;
    }
  );
  const { creditFeePercent } = agencyDetails || {};
  // Determine if the fee checkbox should be shown. It is shown if:
  // - The selected payment method is a card
  // - The credit card fee checkbox feature is enabled
  // - The amount is greater than or equal to the credit card fee threshold amount (or 0 if the threshold is not defined)
  // - The credit fee percent is not 0

  const showFeeCheckbox =
    hppData.paymentMethod === PaymentMethod.Card &&
    isCcFeeCheckboxEnabled &&
    hppData.paymentAmount >= (ccFeeThresholdAmount ?? 0) &&
    creditFeePercent !== 0;

  // Determine if the "Pay Now" button should be disabled. It is disabled if:
  // - The fee checkbox is shown and the fee has not been accepted
  const disablePayNowButton = Boolean(showFeeCheckbox && !isCCFeeAccepted);

  return (
    <Flex className={classes.paymentContainer}>
      <p>{t('CHOOSE_YOUR_PAYMENT_METHOD')}</p>
      {hppData.paymentAmount && (
        <PaymentMethodSelection
          onPaymentMethodSelected={(paymentDetails) => {
            const paymentMethod = paymentDetails.paymentMethod!;

            const { paymentTotal, paymentFee } = getAmountWithFees(
              paymentMethod,
              hppData.paymentAmount,
              paymentMethodConfig[paymentDetails.paymentMethod!].fee,
              currencyMap[locale]
            );

            setHppData({
              paymentMethod,
              paymentTotal,
              paymentFee
            });
          }}
        />
      )}
      <div className={classes.buttonsContainer}>
        {isAccountManagementEnabled && !customerUser && (
          <Button
            className={classes.paymentMethodOnFileButton}
            onClick={!user ? loginModal.open : undefined}
          >
            {t('USE_PAYMENT_METHOD_ON_FILE')}
          </Button>
        )}
        {/* This back button is displayed in the UI before the user selects a
        payment method. */}
        {!hppData.paymentMethod && (
          <Button type="tertiary" onClick={onBack}>
            {t('BACK')}
          </Button>
        )}
      </div>
      {hppData.paymentMethod && (
        <Row>
          <Col xs={12}>
            <AdyenWebComponent
              type={hppData.paymentMethod}
              disablePayNowButton={disablePayNowButton}
              onPaymentSuccess={onPaymentSuccess}
              onBack={onBack}
              setErrorMessage={setErrorMessage}
              storedPaymentMethods={
                storedPaymentMethods.data?.[
                  hppData.paymentMethod === PaymentMethod.Card
                    ? 'cards'
                    : hppData.paymentMethod
                ]
              }
            />
          </Col>
        </Row>
      )}
      {showFeeCheckbox && (
        <Row>
          <Col xs={12} className={classes.checkboxWrapper}>
            <AcceptCCFeeCheckbox
              onCheckboxChange={handleCheckboxChange}
              isChecked={isCCFeeAccepted}
            />
          </Col>
        </Row>
      )}
      {!!errorMessage && <ErrorAlert errorMessage={errorMessage} />}
      {!!storedPaymentMethods.isError && (
        <Flex className={classes.error}>
          <InlineAlert type={BrandType.Warning} closeable={true}>
            {t('ERROR_FAILED_TO_LOAD_PAYMENT_METHODS')}
          </InlineAlert>
        </Flex>
      )}
      <Flex>
        <p className={classes.disclaimer}>
          <sub>
            {' '}
            {t('CONVENIENCE_FEE_DISCLAIMER').replace(
              '{Company_Name}',
              agencyDetails?.name || ''
            )}
          </sub>
        </p>
      </Flex>
    </Flex>
  );
};
