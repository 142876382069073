import DropinElement from '@adyen/adyen-web/dist/types/components/Dropin';
import AdyenCheckoutError from '@adyen/adyen-web/dist/types/core/Errors/AdyenCheckoutError';
import {
  ADSAnyObject,
  ADSChangeEvent,
  Button,
  Col,
  Container,
  Flex,
  Form,
  H1,
  H2,
  ListItem,
  number,
  object,
  Radio,
  RadioGroup,
  Row,
  SimpleList,
  string
} from '@appliedsystems/applied-design-system';
import {
  calculateSplitFromMinorUnits,
  CurrencyCode,
  ErrorCode,
  HppSingleInvoiceValidationMode,
  LocaleCode,
  PaymentAttribute,
  removeNullProperties,
  toIntlFormat,
  toMinorUnits,
  Workflow
} from '@appliedsystems/payments-core';
import { datadogLogs } from '@datadog/browser-logs';
import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAccountManagement } from '../../hooks/useAccountManagement';
import {
  useDisableStoredPaymentMethod,
  useDisableStoredPaymentMethodLastUpdatedDate
} from '../../hooks/useDisableStoredPaymentMethod';
import { useFeatureFlags } from '../../hooks/useFeatureFlag';
import { useHppToken } from '../../hooks/useHppToken';
import { useIsEligibleForFlowV2 } from '../../hooks/useIsEligibleforHppCheckoutUi';
import { usePaymentsTranslation } from '../../hooks/usePaymentsTranslation';
import { getLocale, Translation } from '../../localization/translations';
import { useHppReducer } from '../../reducers/useHppReducer';
import { Locale } from '../../store/Locale';
import { getConfig } from '../../util/config';
import { invertColor } from '../../util/util';
import { AccountManagementModals } from '../AccountManagementModals';
import { AdyenPaymentContainer } from '../AdyenPaymentContainer';
import { CheckboxRecaptcha } from '../CheckboxRecaptcha/CheckboxRecaptcha';
import { Loading } from '../Loading';
import { ManageAccount } from '../ManageAccount';
import { PaymentHeader } from '../PaymentHeader';
import { FormFields } from './FormFields';
import classes from './HostedPaymentPage.module.scss';
import { SkeletonForm } from './SkeletonForm';
import { InvoiceGroup } from './workflows/InvoiceDataGrid';
import {
  InvoiceItem,
  PayByInvoice,
  PayByInvoiceSchema
} from './workflows/PayByInvoice';
import { SingleAmount, SingleAmountSchema } from './workflows/SingleAmount';
import { WorkflowState } from './workflows/WorkflowContext';

const currencyMap = {
  [LocaleCode.en_US]: CurrencyCode.USD,
  [LocaleCode.en_GB]: CurrencyCode.GBP,
  [LocaleCode.en_CA]: CurrencyCode.CAD,
  [LocaleCode.fr_CA]: CurrencyCode.CAD
};

const makeSchema = (
  defaultValues: any,
  t: (key: keyof Translation) => string
) =>
  object({
    firstName: string()
      .required(t('ERROR_FIRST_NAME_REQUIRED'))
      .default(defaultValues.customerFirstName),
    lastName: string()
      .required(t('ERROR_LAST_NAME_REQUIRED'))
      .default(defaultValues.customerLastName),
    businessName: string()
      .optional()
      .default(defaultValues.customerBusinessName),
    email: string()
      .email(t('ERROR_EMAIL_INVALID'))
      .required(t('ERROR_EMAIL_REQUIRED'))
      .default(defaultValues.customerEmail),
    invoiceNumber: string().default(defaultValues.invoiceNumber),
    policyNumber: string().default(defaultValues.policyNumber),
    accountNumber: string().default(defaultValues.accountNumber),
    paymentDescription: string()
      .required()
      .default(defaultValues.paymentDescription),
    amount: number()
      .required(t('ERROR_AMOUNT_REQUIRED'))
      .default(defaultValues.amount)
  });

const errorCodeToTranslationKey: Partial<Record<ErrorCode, keyof Translation>> =
  {
    [ErrorCode.TooManyPspAuthorizationAttempts]:
      'ERROR_TOO_MANY_PSP_AUTHORIZATION_ATTEMPTS',
    [ErrorCode.PaymentAmountTooLow]: 'ERROR_FEE_AMOUNT_BELOW_MINIMUM',
    [ErrorCode.MerchantAccountNotActive]: 'ERROR_MERCHANT_ACCOUNT_NOT_ACTIVE',
    [ErrorCode.PaymentFlowSessionIdMissing]: 'PAYMENT_FLOW_SESSION_ID_MISSING',
    [ErrorCode.AgencyDetailsMissing]: 'AGENCY_DETAILS_MISSING',
    [ErrorCode.FetchAgencyDetailFailedUnknown]:
      'FETCH_AGENCY_DETAIL_FAILED_UNKNOWN',
    [ErrorCode.FetchAgencyEmptyResponse]: 'FETCH_AGENCY_EMPTY_RESPONSE',
    [ErrorCode.FetchAgencyFailed]: 'FETCH_AGENCY_FAILED',
    [ErrorCode.InitAdyenCheckoutFailed]: 'INIT_ADYEN_CHECKOUT_FAILED',
    [ErrorCode.GetHPPSessionFailedTokenMissing]:
      'GET_HPP_SESSION_FAILED_TOKEN_MISSING',
    [ErrorCode.GetHPPSessionFailedUnknown]: 'GET_HPP_SESSION_FAILED_UNKNOWN',
    [ErrorCode.GetHPPSessionEmptyResponse]: 'GET_HPP_SESSION_EMPTY_RESPONSE',
    [ErrorCode.GetHPPSessionErrorUnknown]: 'GET_HPP_SESSION_ERROR_UNKNOWN',
    [ErrorCode.RecaptchaVerificationFailed]: 'RECAPTCHA_VERIFICATION_FAILED',
    [ErrorCode.ValidationHandlerNotSet]: 'VALIDATION_HANDLER_NOT_SET'
  };

export const HostedPaymentPage = (): React.ReactElement => {
  const navigate = useNavigate();
  const { hppToken: legacyHppToken, hppConfig: legacyHppConfig } =
    useHppToken();
  const { t } = usePaymentsTranslation();
  const { locale, setLocale } = Locale.useContainer();
  const {
    isHppLoading,
    isDropinLoading,
    isPaymentSuccess,
    getAgencyDetail,
    agencyDetail,
    getHppSession,
    reloadHppSession,
    hppSessionRequest,
    hppSessionDetail,
    updatePaymentMethod,
    paymentMethod,
    initPayForm,
    dropinElement,
    makePayment,
    editPaymentInfo,
    setComponentStatus,
    setFatalError,
    hasFatalError,
    errorCode,
    errorDetail
  } = useHppReducer(legacyHppToken);

  useIsEligibleForFlowV2(agencyDetail?.tenantId, '/flow/hppv2');

  const hppConfig = agencyDetail?.config ?? legacyHppConfig;
  const hppToken = agencyDetail?.token ?? legacyHppToken;

  const [recaptchaAlertOpen, setRecaptchaAlertOpen] = React.useState(false);

  React.useEffect(() => {
    if (Object.values(LocaleCode).includes(hppConfig.locale as any))
      setLocale(hppConfig.locale as LocaleCode);
  }, [hppConfig.locale, setLocale]);

  const {
    isAccountManagementEnabled: isAccountManagementFlagEnabled,
    customerUser,
    manageAccountWindow
  } = useAccountManagement(agencyDetail?.appliedProductId, hppToken);
  const { isFeatureFlagsLoading } = useFeatureFlags();
  const { onDisableStoredPaymentMethod } = useDisableStoredPaymentMethod();
  const isAccountManagementEnabled =
    isAccountManagementFlagEnabled && agencyDetail?.manageMyAccountEnabled;

  React.useEffect(() => {
    datadogLogs.setGlobalContextProperty('context', {
      tenant: agencyDetail?.tenantId ?? undefined,
      merchantId: agencyDetail?.merchantId ?? undefined,
      merchantAccountId: agencyDetail?.merchantAccountId ?? undefined,
      sessionId: datadogLogs.getInternalContext()?.session_id,
      customerUserId: customerUser?.id
    });
  }, [agencyDetail, customerUser?.id, hppToken]);

  const [formSchema, setFormSchema] = React.useState<ADSAnyObject>();
  const [invoiceOption, setInvoiceOption] = React.useState<
    'SINGLE_AMOUNT' | 'PAY_BY_INVOICE'
  >('PAY_BY_INVOICE');
  const [workflowValidated, setWorkflowValidated] =
    React.useState<boolean>(false);
  const [invoicesToPay, setInvoicesToPay] = React.useState<
    InvoiceGroup[] | null
  >(null);
  const [clientId, setClientId] = React.useState<string | undefined>(undefined);
  const [amount, setAmount] = React.useState<number>(0);
  const [amountWithFee, setAmountWithFee] = React.useState<{
    subtotal: {
      ach: string;
      card: string;
    };
    fee: {
      ach: string;
      card: string;
    };
    total: {
      ach: string;
      card: string;
    };
  } | null>(null);
  const [onRecaptchaSuccess, setOnRecaptchaSuccess] =
    useState<(recaptchaToken: string) => Promise<void>>();
  const [validationHandler, setValidationHandler] =
    React.useState<(onSuccess: any, onError?: any) => () => Promise<any>>();

  useEffect(() => {
    setFormSchema(
      makeSchema(
        {
          customerFirstName: customerUser?.firstName,
          customerLastName: customerUser?.lastName,
          customerEmail: customerUser?.email,
          accountNumber: customerUser?.accountNumber,
          locale: getLocale(locale)
        },
        t
      )
    );
    getAgencyDetail();
    // eslint-disable-next-line
  }, [customerUser]);

  // make sure invoiceOption is always valid for the options on the agency
  useEffect(() => {
    if (!agencyDetail) return;
    if (!agencyDetail.payByInvoiceEnabled && !agencyDetail.singleAmountEnabled)
      return;
    if (invoiceOption === 'PAY_BY_INVOICE' && !agencyDetail.payByInvoiceEnabled)
      setInvoiceOption('SINGLE_AMOUNT');
    else if (
      invoiceOption === 'SINGLE_AMOUNT' &&
      !agencyDetail.singleAmountEnabled
    )
      setInvoiceOption('PAY_BY_INVOICE');
  }, [agencyDetail, invoiceOption]);

  useEffect(() => {
    const amountWithFee = getAmountWithFees(amount, false);
    setAmountWithFee(amountWithFee);
    // eslint-disable-next-line
  }, [amount]);

  useEffect(() => {
    const hppLogMessageDisplayed = sessionStorage.getItem(
      'hppLogMessageDisplayed'
    );
    if (!hppLogMessageDisplayed) {
      datadogLogs.logger.info('New hpp user session started');
      sessionStorage.setItem('hppLogMessageDisplayed', 'true');
    }
  }, []);

  const getAmountWithFees = (amount: number, isMinorUnits: boolean = false) => {
    if (!(amount && amount > 0)) {
      return null;
    }
    const currency = currencyMap[locale];
    const amountInMinorUnits = isMinorUnits
      ? amount
      : toMinorUnits(amount, currency);
    const [percentage] = calculateSplitFromMinorUnits(
      agencyDetail?.fees?.feeCredit ?? 0,
      amountInMinorUnits || 0,
      currency
    );
    return {
      subtotal: {
        card: toIntlFormat(
          {
            amount: amountInMinorUnits,
            currencyCode: currency
          },
          locale
        ),
        ach: toIntlFormat(
          {
            amount: amountInMinorUnits,
            currencyCode: currency
          },
          locale
        )
      },
      fee: {
        card: toIntlFormat(
          {
            amount: percentage.amount,
            currencyCode: currency
          },
          locale
        ),
        ach: toIntlFormat(
          {
            amount: agencyDetail?.fees?.feeAch || 0,
            currencyCode: currency
          },
          locale
        )
      },
      total: {
        card: toIntlFormat(
          {
            amount: amountInMinorUnits + percentage.amount,
            currencyCode: currency
          },
          locale
        ),
        ach: toIntlFormat(
          {
            amount: amountInMinorUnits + (agencyDetail?.fees?.feeAch || 0),
            currencyCode: currency
          },
          locale
        )
      }
    };
  };

  const isCustomerUserAuthorized = !!customerUser;
  useEffect(() => {
    if (!hppSessionDetail) {
      return;
    }

    initPayForm('#dropin-container', {
      onError: (error: AdyenCheckoutError, component?: DropinElement) => {
        console.error(
          'HPP Adyen onError called',
          error,
          component?.props?.session,
          hppSessionDetail
        );
        if (component) setComponentStatus('ready', component);
        return;
      },
      onSelect: (paymentMethod) => {
        updatePaymentMethod(paymentMethod);
      },
      onSubmit: (state, component: DropinElement) => {
        if (state.data.paymentMethod.type === 'scheme') {
          state.data.paymentMethod.holderName = `${hppSessionRequest?.customer?.firstName} ${hppSessionRequest?.customer?.lastName}`;
        }
        setOnRecaptchaSuccess(() => async (recaptchaToken: string) => {
          setRecaptchaAlertOpen(false);
          await makePayment(
            state.data.paymentMethod,
            component,
            recaptchaToken,
            state.data.storePaymentMethod
          );
        });
        setRecaptchaAlertOpen(true);
      },
      enableStoreDetails: isCustomerUserAuthorized,
      onDisableStoredPaymentMethod
    });
    // eslint-disable-next-line
  }, [hppSessionDetail]);

  const resetForm = () => {
    if (workflow > 1) {
      setWorkflowValidated(false);
      editPaymentInfo();
      return;
    }
    const defaultValues = {
      customerFirstName:
        hppSessionRequest?.customer?.firstName || customerUser?.firstName,
      customerLastName:
        hppSessionRequest?.customer?.lastName || customerUser?.lastName,
      businessName: hppSessionRequest?.customer?.businessName,
      customerEmail: hppSessionRequest?.customer?.email || customerUser?.email,
      invoiceNumber: hppSessionRequest?.paymentAttributes?.find(
        (e: any) => e.name === PaymentAttribute.InvoiceNumber
      )?.value,
      policyNumber: hppSessionRequest?.paymentAttributes?.find(
        (e: any) => e.name === PaymentAttribute.PolicyNumber
      )?.value,
      paymentDescription: hppSessionRequest?.paymentDescription,
      amount,
      accountNumber: customerUser?.accountNumber
    };
    editPaymentInfo();
    setFormSchema(
      makeSchema(
        {
          ...defaultValues,
          locale: getLocale(locale)
        },
        t
      )
    );
  };

  const workflow: Workflow = useMemo(() => {
    if (!agencyDetail) return Workflow.Default;

    /**
     * multiInvoiceEnabled: false (Individual Invoice)
     * multiInvoiceEnabled: true (All Open Invoices)
     * singleInvoiceValidationMode: bypass  (Enable client validation and auto create the transaction in Epic leaving the money on the client’s account. The client will have 3 attempts within a 24 hour period to validate, or can choose to bypass validation immediately. If validation fails the client will continue to the next step without validating and a transaction will not be auto created in Epic.)
     * singleInvoiceValidationMode: rate limit (Enable client validation and auto create the transaction in Epic leaving the money on the client’s account. The client will have 3 attempts within a 24 hour period to validate. If validation fails the client will continue to the next step without validating and a transaction will not be auto created in Epic.)
     * singleInvoiceValidationMode: required (Require client validation. The client will not be able to pay without validation using 5 attempts within a 24 hour period.)
     */
    const {
      multiInvoiceEnabled,
      singleInvoiceValidationMode,
      payByInvoiceEnabled,
      singleAmountEnabled
    } = agencyDetail;

    if (!singleAmountEnabled && !payByInvoiceEnabled) {
      return Workflow.Default;
    }
    if (singleAmountEnabled) {
      // Workflow #2: Single Amount is selected by the payer, validation is enabled and multiInvoiceEnabled is false and singleInvoiceValidationMode is required to require validation and not allow bypass.
      if (
        invoiceOption === 'SINGLE_AMOUNT' &&
        singleInvoiceValidationMode ===
          HppSingleInvoiceValidationMode.VALIDATION_REQUIRED
      ) {
        return Workflow.SingleAmount;
      }
      // Workflow #2.5: Single Amount is selected by the payer, validation is enabled, multiInvoiceEnabled is false and singleInvoiceValidationMode is rate limit to allow rate limit bypass.
      if (
        invoiceOption === 'SINGLE_AMOUNT' &&
        singleInvoiceValidationMode ===
          HppSingleInvoiceValidationMode.RATE_LIMIT_BYPASS_ONLY
      ) {
        return Workflow.SingleAmountWithRateLimitBypass;
      }
      // Workflow #2.75: Single Amount is selected by the payer, validation is enabled, multiInvoiceEnabled is false and singleInvoiceValidationMode is bypass to allow rate limit or manual bypass.
      if (
        invoiceOption === 'SINGLE_AMOUNT' &&
        singleInvoiceValidationMode ===
          HppSingleInvoiceValidationMode.BYPASS_ENABLED
      ) {
        return Workflow.SingleAmountWithOptionalBypass;
      }
    }
    if (payByInvoiceEnabled) {
      // Workflow #3: Pay by Invoice is selected by the payer, validation is enabled and multiInvoiceEnabled is false to allow them to only select 1 invoice.
      if (invoiceOption === 'PAY_BY_INVOICE' && !multiInvoiceEnabled) {
        return Workflow.SingleInvoice;
      }
      // Workflow #4: Pay by Invoice is selected by the payer, validation is enabled and multiInvoiceEnabled is true to allow them to select multiple invoices.
      if (invoiceOption === 'PAY_BY_INVOICE' && multiInvoiceEnabled) {
        return Workflow.MultiInvoice;
      }
    }

    // error, should not reach here but revert to workflow 1 if it does to unblock.
    return 1;
  }, [agencyDetail, invoiceOption]);

  const handlePaymentMethodClick = () => {
    if (!validationHandler || typeof validationHandler !== 'function') {
      console.error('Validation handler not set');
      setFatalError(ErrorCode.ValidationHandlerNotSet);
      return;
    }

    validationHandler?.((_values: any) => {
      const attributes = [];
      if (_values.invoiceNumber) {
        attributes.push({
          name: PaymentAttribute.InvoiceNumber,
          value: _values.invoiceNumber
        });
      }
      if (_values.policyNumber) {
        attributes.push({
          name: PaymentAttribute.PolicyNumber,
          value: _values.policyNumber
        });
      }
      if (_values.accountNumber) {
        attributes.push({
          name: PaymentAttribute.AccountNumber,
          value: _values.accountNumber
        });
      }
      if (amount) {
        attributes.push({
          name: PaymentAttribute.PaymentAmount,
          value: toMinorUnits(amount, currencyMap[locale])
        });
      }
      const values = removeNullProperties(_values);
      (async () => {
        getHppSession({
          customer: {
            firstName: values.firstName,
            lastName: values.lastName,
            businessName: values.businessName,
            email: values.email
          },
          locale: locale,
          amount: toMinorUnits(amount, currencyMap[locale]),
          paymentDescription: values.paymentDescription,
          paymentAttributes: attributes,
          workflow,
          ...(clientId && {
            epicInvoiceMeta: {
              clientId
            }
          })
        });
      })();
    })();
  };

  const handleWorkflowValidationSuccess = (data?: SingleAmountSchema) => {
    setFormSchema(
      makeSchema(
        {
          customerFirstName: customerUser?.firstName,
          customerLastName: customerUser?.lastName,
          customerEmail: customerUser?.email,
          locale: getLocale(locale),
          accountNumber: data?.accountNumber
        },
        t
      )
    );
    setClientId(data?.clientId);
    setWorkflowValidated(true);
  };

  const handleInvoiceLookupSuccess = (
    values: PayByInvoiceSchema,
    invoices: InvoiceGroup[],
    items: InvoiceItem[],
    clientId: string
  ) => {
    setInvoicesToPay(invoices);

    const amountMinorUnits = invoices.reduce(
      (sum, invoice) => sum + +invoice.amountDueMinorUnits,
      0
    );

    const amountWithFees = getAmountWithFees(amountMinorUnits, true);
    setAmountWithFee(amountWithFees);

    const splitPaymentAttributes = invoices.map((invoice) => {
      return [
        {
          name: PaymentAttribute.InvoiceNumber,
          value: invoice.invoiceNumber
        },
        {
          name: PaymentAttribute.AccountNumber,
          value: values.accountNumber
        },
        {
          name: PaymentAttribute.PaymentAmount,
          value: invoice.amountDueMinorUnits.toString()
        }
      ];
    });
    const paymentDescription = `${t('PAYMENT_AGAINST_INVOICE')}: ${invoices
      .map((e) => e.invoiceNumber)
      .join(', ')}.`;
    (async () => {
      getHppSession({
        customer: {
          firstName: values.firstName,
          lastName: values.lastName,
          businessName: values.businessName,
          email: values.email
        },
        locale: locale,
        amount: amountMinorUnits,
        paymentDescription,
        splitPaymentAttributes,
        workflow,
        epicInvoiceMeta: {
          clientId: clientId,
          transactions: items
        }
      });
    })();
    setWorkflowValidated(true);
  };

  const prevCustomerUser = React.useRef(customerUser);
  useEffect(() => {
    if (dropinElement && prevCustomerUser.current !== customerUser) {
      reloadHppSession();
    }

    prevCustomerUser.current = customerUser;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropinElement, customerUser]);

  useEffect(() => {
    if (hasFatalError) {
      const translationKey =
        errorCode && Object.keys(errorCodeToTranslationKey).includes(errorCode)
          ? errorCodeToTranslationKey[errorCode]
          : 'ERROR_ADYEN_CHECKOUT';

      navigate('/flow/error', {
        state: { errorCode: translationKey, errorDetail }
      });
    }
  }, [hasFatalError, errorCode, navigate, errorDetail]);

  const { storedPaymentMethodsLastUpdatedDate } =
    useDisableStoredPaymentMethodLastUpdatedDate();
  useEffect(() => {
    if (storedPaymentMethodsLastUpdatedDate) {
      // If stored payment methods have been updated, reinitialize checkout
      reloadHppSession();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storedPaymentMethodsLastUpdatedDate]);

  const logoUrl =
    hppConfig.showLogo && agencyDetail?.imageId
      ? `${getConfig('REACT_APP_PAYMENTS_API_BASE_URL')}/image/${
          agencyDetail?.imageId
        }`
      : undefined;

  let primaryColor = agencyDetail?.primaryColor || '#365ef6';
  let primaryTextColor = agencyDetail?.primaryTextColor || '#ffffff';
  const border = primaryColor.toLowerCase() === '#ffffff'; // show border if bg color matches page
  if (primaryColor.toLowerCase() === primaryTextColor.toLowerCase())
    // invert text color so it can be seen
    primaryTextColor = invertColor(primaryTextColor);

  if (isFeatureFlagsLoading) return <Loading />;
  return (
    <>
      <div
        className={classes.wrapper}
        style={{ overflow: manageAccountWindow.isOpen ? 'hidden' : 'auto' }}
      >
        {/*// @ts-ignore*/}
        <WorkflowState.Provider>
          <>
            <style>{`
        .adyen-checkout__button:not(.adyen-checkout__button--link):not(.adyen-checkout__payment-method__disable-confirmation__button) {          
          background-color: ${primaryColor} !important;
          color: ${primaryTextColor} !important;
          ${border ? 'border-width: 2px;' : ''}
          ${border ? 'border-style: solid;' : ''}
        }
        .adyen-checkout__button__icon {
          ${agencyDetail?.primaryTextColor ? 'display: none;' : ''}
        }
        .adyen-checkout__payment-method--selected {
          background-color: #fff;
          margin-bottom: 16px !important;
        }
        .adyen-checkout__payment-method--selected + .adyen-checkout__payment-method, .adyen-checkout__payment-method:first-child {
          border-radius: 0;
        }
      `}</style>
            <Container className={classes.container}>
              <Row className={classes.gapFix}>
                <Col xs={0} md={2} lg={3}></Col>
                <Col xs={12} md={8} lg={6}>
                  <div className={classes.app}>
                    {isAccountManagementEnabled && (
                      <PaymentHeader paymentType="hpp" />
                    )}

                    <Flex
                      className={`${classes.container} flex-d-column gap-0`}
                    >
                      {hppConfig.showLogo && agencyDetail?.imageId && (
                        <img
                          src={logoUrl}
                          alt={agencyDetail.name || ''}
                          className={classes.logo}
                        />
                      )}
                      {hppConfig.showTitle && (
                        <>
                          <Flex className={`flex-d-column`}>
                            <H1 className="text-center">
                              {t('MAKE_A_PAYMENT')}
                            </H1>
                            <p className={`${classes.subheader} text-center`}>
                              {t('ENTER_PAYMENT_INFO')}
                            </p>
                          </Flex>
                        </>
                      )}
                      {!workflowValidated && (
                        <>
                          {workflow !== Workflow.Default &&
                            !!agencyDetail?.singleAmountEnabled &&
                            !!agencyDetail?.payByInvoiceEnabled && (
                              /* only show this is > workflow 1, non-regular workflows */
                              <RadioGroup
                                className="text-center mt-150"
                                name="hppMultiInvoiceEnabled"
                                onChange={(event: ADSChangeEvent) => {
                                  setInvoiceOption(event.target.value);
                                }}
                                value={invoiceOption}
                              >
                                <Radio value={'SINGLE_AMOUNT'}>
                                  {t('SINGLE_AMOUNT')}
                                </Radio>
                                <Radio value={'PAY_BY_INVOICE'}>
                                  {t('PAY_BY_INVOICE')}
                                </Radio>
                              </RadioGroup>
                            )}
                          {[
                            Workflow.SingleAmount,
                            Workflow.SingleAmountWithRateLimitBypass,
                            Workflow.SingleAmountWithOptionalBypass
                          ].includes(workflow) && (
                            <SingleAmount
                              hppToken={hppToken}
                              locale={locale}
                              readonly={!!dropinElement}
                              onSuccess={handleWorkflowValidationSuccess}
                              initialLoadingState={
                                isHppLoading || isDropinLoading || !formSchema
                              }
                              allowRateLimitBypass={[
                                Workflow.SingleAmountWithRateLimitBypass,
                                Workflow.SingleAmountWithOptionalBypass
                              ].includes(workflow)}
                              allowManualBypass={[
                                Workflow.SingleAmountWithOptionalBypass
                              ].includes(workflow)}
                            />
                          )}
                          {[3, 4].includes(workflow) && (
                            <PayByInvoice
                              hppToken={hppToken}
                              locale={locale}
                              readonly={!!dropinElement}
                              onSuccess={handleInvoiceLookupSuccess}
                              initialLoadingState={
                                isHppLoading || isDropinLoading || !formSchema
                              }
                              lookupAllInvoices={
                                workflow === Workflow.MultiInvoice
                              }
                            />
                          )}
                        </>
                      )}
                      {(workflow === Workflow.Default || workflowValidated) &&
                        !dropinElement && (
                          <>
                            <div
                              className={classNames(
                                classes.form,
                                classes.topForm
                              )}
                            >
                              {isHppLoading ||
                              isDropinLoading ||
                              !formSchema ? (
                                <SkeletonForm />
                              ) : (
                                <Form schema={formSchema}>
                                  <FormFields
                                    locale={locale}
                                    setValidationHandler={setValidationHandler}
                                    setAmount={setAmount}
                                    readonly={!!dropinElement}
                                  />
                                </Form>
                              )}
                            </div>

                            <div className="text-right">
                              {(workflow === Workflow.SingleAmount ||
                                workflow ===
                                  Workflow.SingleAmountWithRateLimitBypass) && (
                                <Button
                                  disabled={isDropinLoading}
                                  onClick={() => setWorkflowValidated(false)}
                                  icon={
                                    isDropinLoading ? 'SpinnerIcon' : undefined
                                  }
                                  className="mr-50"
                                >
                                  {t('BACK')}
                                </Button>
                              )}
                              <Button
                                disabled={!(amount > 0) || isDropinLoading}
                                type="primary"
                                onClick={handlePaymentMethodClick}
                                icon={
                                  isDropinLoading ? 'SpinnerIcon' : undefined
                                }
                              >
                                {t('NEXT')}
                              </Button>
                            </div>
                          </>
                        )}

                      <div
                        style={{ display: !dropinElement ? 'none' : 'inherit' }}
                        className={`${classes.container} flex-container flex-d-column gap-0 p-0`}
                      >
                        {[
                          Workflow.Default,
                          Workflow.SingleAmount,
                          Workflow.SingleAmountWithRateLimitBypass,
                          Workflow.SingleAmountWithOptionalBypass
                        ].includes(workflow) && (
                          <>
                            <div
                              className={classNames(
                                classes.form,
                                classes.topForm
                              )}
                            >
                              <div className={classes.orderSummary}>
                                <SimpleList hideBorder>
                                  <ListItem>
                                    <div className={`${classes.listItem}`}>
                                      <div>
                                        <b>{t('NAME')}</b>
                                      </div>
                                      <div>
                                        {hppSessionRequest?.customer?.firstName}{' '}
                                        {hppSessionRequest?.customer?.lastName}
                                      </div>
                                    </div>
                                  </ListItem>
                                  <ListItem>
                                    <div className={`${classes.listItem}`}>
                                      <div>
                                        <b>{t('BUSINESS_NAME')}</b>
                                      </div>
                                      <div>
                                        {hppSessionRequest?.customer
                                          ?.businessName || '-'}
                                      </div>
                                    </div>
                                  </ListItem>
                                  <ListItem>
                                    <div className={`${classes.listItem}`}>
                                      <div>
                                        <b>{t('EMAIL')}</b>
                                      </div>
                                      <div>
                                        {hppSessionRequest?.customer?.email}
                                      </div>
                                    </div>
                                  </ListItem>
                                  <ListItem>
                                    <div className={`${classes.listItem}`}>
                                      <div>
                                        <b>{t('POLICY_NUMBER')}</b>
                                      </div>
                                      <div>
                                        {hppSessionRequest?.paymentAttributes?.find(
                                          (e: any) =>
                                            e.name ===
                                            PaymentAttribute.PolicyNumber
                                        )?.value || '-'}
                                      </div>
                                    </div>
                                  </ListItem>
                                  <ListItem>
                                    <div className={`${classes.listItem}`}>
                                      <div>
                                        <b>{t('INVOICE_NUMBER')}</b>
                                      </div>
                                      <div>
                                        {hppSessionRequest?.paymentAttributes?.find(
                                          (e: any) =>
                                            e.name ===
                                            PaymentAttribute.InvoiceNumber
                                        )?.value || '-'}
                                      </div>
                                    </div>
                                  </ListItem>
                                  <ListItem>
                                    <div className={`${classes.listItem}`}>
                                      <div>
                                        <b>{t('PAYMENT_DESCRIPTION')}</b>
                                      </div>
                                      <div>
                                        {hppSessionRequest?.paymentDescription ||
                                          '-'}
                                      </div>
                                    </div>
                                  </ListItem>
                                </SimpleList>
                              </div>
                              <SimpleList>
                                <ListItem>
                                  <div className={`${classes.listItem}`}>
                                    <div>{t('SUBTOTAL')}</div>
                                    <div>
                                      {
                                        amountWithFee?.subtotal[
                                          paymentMethod || 'card'
                                        ]
                                      }
                                    </div>
                                  </div>
                                </ListItem>
                                <ListItem>
                                  <div className={`${classes.listItem}`}>
                                    <div>{t('CONVENIENCE_FEE')}</div>
                                    <div className={classes.feeWrapper}>
                                      {
                                        amountWithFee?.fee[
                                          paymentMethod || 'card'
                                        ]
                                      }
                                    </div>
                                  </div>
                                </ListItem>
                                <ListItem>
                                  <div className={`${classes.listItem}`}>
                                    <div>
                                      <b>{t('TOTAL')}</b>
                                    </div>
                                    <div>
                                      <b>
                                        {
                                          amountWithFee?.total[
                                            paymentMethod || 'card'
                                          ]
                                        }
                                      </b>
                                    </div>
                                  </div>
                                </ListItem>
                              </SimpleList>
                            </div>
                          </>
                        )}
                        {[
                          Workflow.SingleInvoice,
                          Workflow.MultiInvoice
                        ].includes(workflow) && (
                          <>
                            <H2 className="mt-100 mb-0">
                              {t('ORDER_SUMMARY')}
                            </H2>
                            <p>{t('REVIEW_THE_ITEMS_BELOW')}</p>
                            <div
                              className={classNames(
                                classes.form,
                                classes.topForm
                              )}
                            >
                              <div className={classes.orderSummary}>
                                <SimpleList hideBorder>
                                  <ListItem>
                                    <div className={`${classes.listItem}`}>
                                      <div>
                                        <b>{t('NAME')}</b>
                                      </div>
                                      <div>
                                        {hppSessionRequest?.customer?.firstName}{' '}
                                        {hppSessionRequest?.customer?.lastName}
                                      </div>
                                    </div>
                                  </ListItem>
                                  <ListItem>
                                    <div className={`${classes.listItem}`}>
                                      <div>
                                        <b>{t('BUSINESS_NAME')}</b>
                                      </div>
                                      <div>
                                        {hppSessionRequest?.customer
                                          ?.businessName || '-'}
                                      </div>
                                    </div>
                                  </ListItem>
                                  <ListItem>
                                    <div className={`${classes.listItem}`}>
                                      <div>
                                        <b>{t('EMAIL')}</b>
                                      </div>
                                      <div>
                                        {hppSessionRequest?.customer?.email}
                                      </div>
                                    </div>
                                  </ListItem>
                                  <ListItem>
                                    <div className={`${classes.listItem}`}>
                                      <div>
                                        <b>{t('PAYMENT_DESCRIPTION')}</b>
                                      </div>
                                      <div>
                                        {hppSessionRequest?.paymentDescription ||
                                          '-'}
                                      </div>
                                    </div>
                                  </ListItem>
                                </SimpleList>
                              </div>

                              <SimpleList>
                                {invoicesToPay?.map((invoice) => (
                                  <ListItem>
                                    <div className={`${classes.listItem}`}>
                                      <div className="pl-100">
                                        {t('INVOICE_NUMBER')}:{' '}
                                        {invoice.invoiceNumber}
                                      </div>
                                      <div>{invoice.amountDue}</div>
                                    </div>
                                  </ListItem>
                                ))}
                                <ListItem>
                                  <div className={`${classes.listItem} pl-100`}>
                                    <div>{t('SUBTOTAL')}</div>
                                    <div>
                                      {
                                        amountWithFee?.subtotal[
                                          paymentMethod || 'card'
                                        ]
                                      }
                                    </div>
                                  </div>
                                </ListItem>
                                <ListItem>
                                  <div className={`${classes.listItem}`}>
                                    <div className="pl-100">
                                      {t('CONVENIENCE_FEE')}
                                    </div>
                                    <div className={classes.feeWrapper}>
                                      {
                                        amountWithFee?.fee[
                                          paymentMethod || 'card'
                                        ]
                                      }
                                    </div>
                                  </div>
                                </ListItem>
                                <ListItem>
                                  <div className={`${classes.listItem}`}>
                                    <div>
                                      <b>{t('TOTAL')}</b>
                                    </div>
                                    <div>
                                      <b>
                                        {
                                          amountWithFee?.total[
                                            paymentMethod || 'card'
                                          ]
                                        }
                                      </b>
                                    </div>
                                  </div>
                                </ListItem>
                              </SimpleList>
                            </div>
                          </>
                        )}
                        {!isPaymentSuccess && (
                          <div
                            className={classNames(
                              'text-right',
                              classes.editPaymentButton
                            )}
                          >
                            <Button type="link" onClick={resetForm}>
                              {t('EDIT_THIS_PAYMENT')}
                            </Button>
                          </div>
                        )}
                        <AdyenPaymentContainer
                          isAccountManagementEnabled={
                            isAccountManagementEnabled
                          }
                          isPaymentSuccess={isPaymentSuccess}
                          isUserLoggedIn={!!customerUser}
                        />
                      </div>
                    </Flex>
                  </div>
                </Col>
                <Col xs={0} md={2} lg={3}></Col>
              </Row>
              <Row className={classes.gapFix}>
                <Col xs={0} md={2} lg={3}></Col>
                <Col xs={12} md={8} lg={6} className="text-center">
                  <p style={{ margin: 0 }}>
                    <sub>
                      {' '}
                      {t('CONVENIENCE_FEE_DISCLAIMER').replace(
                        '{Company_Name}',
                        agencyDetail?.name || ''
                      )}
                    </sub>
                  </p>
                </Col>
                <Col xs={0} md={2} lg={3}></Col>
              </Row>
              {hppConfig.showCopyright && (
                <p style={{ marginTop: 0 }} className="text-center">
                  <sub>
                    {t('COPYRIGHT')} &copy; {agencyDetail?.name}{' '}
                    {new Date().getFullYear()}
                  </sub>
                </p>
              )}
              {hppToken && (
                <AccountManagementModals paymentSessionToken={hppToken} />
              )}
            </Container>
          </>
        </WorkflowState.Provider>
      </div>
      {isAccountManagementEnabled && hppToken && (
        <ManageAccount
          brand={{
            name: agencyDetail?.name ?? '',
            logoUrl: logoUrl
          }}
          locale={locale}
          paymentSessionToken={hppToken}
        />
      )}

      <CheckboxRecaptcha
        onError={setFatalError}
        onSuccess={onRecaptchaSuccess!}
        recaptchaAlertOpen={recaptchaAlertOpen}
        action="purchase"
      />
    </>
  );
};
