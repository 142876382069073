import { useState } from 'react';

export const useHppAccordion = <T, K extends keyof T>(initial: {
  [key in K]: { isValid: boolean; isOpen: boolean; dependsOn: K[] };
}) => {
  const [accordionStates, setAccordionStates] = useState(initial);

  const checkDependencies = (key: K) =>
    accordionStates[key].dependsOn.length === 0 ||
    accordionStates[key].dependsOn.every(
      (dependency) => accordionStates[dependency].isValid
    );

  const updateAccordions = (toUpdate: {
    [key in K]?: { isValid?: boolean; isOpen?: boolean };
  }) => {
    const nextState = {
      ...accordionStates
    };

    for (let key in toUpdate) {
      // need to invalidate all other steps that depend on it
      if (toUpdate[key] && toUpdate[key]!.isValid === false) {
        (Object.keys(accordionStates) as K[]).forEach((k) => {
          const dependsOnTarget = accordionStates[k].dependsOn.includes(key);
          if (dependsOnTarget) {
            accordionStates[k].isValid = false;
            accordionStates[k].isOpen = false;
          }
        });
      }
      nextState[key] = {
        ...accordionStates[key],
        ...toUpdate[key]
      };
    }
    setAccordionStates((prev) => ({
      ...prev,
      ...nextState
    }));
  };

  return {
    accordionStates,
    checkDependencies,
    updateAccordions
  };
};
