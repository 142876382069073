import AdyenCheckout from '@adyen/adyen-web';
import DropinElement from '@adyen/adyen-web/dist/types/components/Dropin';
import { DropinElementProps } from '@adyen/adyen-web/dist/types/components/Dropin/types';
import AdyenCheckoutError from '@adyen/adyen-web/dist/types/core/Errors/AdyenCheckoutError';
import { CoreOptions } from '@adyen/adyen-web/dist/types/core/types';
import {
  HostedPaymentSessionPayload,
  PspSessionResponsePayload
} from '@appliedsystems/payments-core';
import { v4 as uuidv4 } from 'uuid';
import { TranslateFunc } from '../hooks/usePaymentsTranslation';
import { getConfig } from './config';

export type AdyenCheckoutState = {
  data: {
    riskData: {
      clientData: string;
    };
    paymentMethod: {
      type: string; // ach | scheme
      checkoutAttemptId: string;

      // ach info
      encryptedBankAccountNumber?: string;
      encryptedBankLocationId?: string;
      ownerName?: string;

      // card info
      holderName?: string;
      encryptedCardNumber?: string;
      encryptedExpiryMonth?: string;
      encryptedExpiryYear?: string;
      encryptedSecurityCode?: string;
      brand?: string;
    };
    browserInfo?: {
      acceptHeader: string;
      colorDepth: number;
      language: string;
      javaEnabled: boolean;
      screenHeight: number;
      screenWidth: number;
      userAgent: string;
      timeZoneOffset: number;
    };
    origin?: string;
    clientStateDataIndicator: boolean;
    storePaymentMethod?: boolean;
  };
  isValid: boolean;
};

export type AdyenConfiguration = {
  beforeSubmit: CoreOptions['beforeSubmit'];
  onReady: () => void;
  onPaymentCompleted: (result: any, component: any) => void;
  onError: (error: AdyenCheckoutError, component?: DropinElement) => void;
  onSelect: (paymentMethod: 'card' | 'ach') => void;
  onSubmit: (state: AdyenCheckoutState, component: DropinElement) => void;
  onAdditionalDetails: (state: any, component: any) => void;
  onDisableStoredPaymentMethod?: (
    recurringDetailReference: string,
    resolve: () => void,
    reject: () => void
  ) => void;
  showStoredPaymentMethods?: boolean;
  showPayButton?: boolean;
  allowPaymentMethods?: string[];
  enableStoreDetails?: boolean;
};

export const initAdyenCheckout = async (
  domNode: string | HTMLElement,
  sessionDetails: Pick<
    PspSessionResponsePayload | HostedPaymentSessionPayload,
    'pspSessionId' | 'sessionData'
  >,
  t: TranslateFunc,
  config?: Partial<AdyenConfiguration>
) => {
  const checkout = await AdyenCheckout({
    environment: getConfig('REACT_APP_ADYEN_ENVIRONMENT'),
    clientKey: getConfig('REACT_APP_ADYEN_CLIENT_KEY'),
    session: {
      id: sessionDetails.pspSessionId,
      sessionData: sessionDetails.sessionData
    },
    showPayButton: config?.showPayButton ?? true,
    beforeSubmit: config?.beforeSubmit,
    onPaymentCompleted: config?.onPaymentCompleted,
    onError: config?.onError,
    onSubmit: config?.onSubmit,
    // TODO: Need to wire up to handle actions like 3DSecure
    onAdditionalDetails: config?.onAdditionalDetails,
    paymentMethodsConfiguration: {
      storedCard: {
        amount: undefined
      },
      card: {
        hasHolderName: false,
        holderNameRequired: false,
        amount: undefined, // hides the amount in the pay button
        billingAddressRequired: false,
        enableStoreDetails: config?.enableStoreDetails
      },
      ach: {
        hasHolderName: true,
        holderNameRequired: false,
        // Keep this ts-ignore as billingAddressRequired is not in the adyen types
        // It's working as expected and billing address is not required
        // @ts-ignore
        billingAddressRequired: false,
        enableStoreDetails: config?.enableStoreDetails
      }
    },
    translations: {
      'en-US': {
        confirmPurchase: t('PAY')
      },
      'en-CA': {
        confirmPurchase: t('PAY')
      },
      'en-GB': {
        confirmPurchase: t('PAY')
      },
      'fr-CA': {
        confirmPurchase: t('PAY')
      }
    },
    allowPaymentMethods: config?.allowPaymentMethods
  });

  const dropinElementProps: DropinElementProps = {
    instantPaymentTypes: [],
    openFirstPaymentMethod: true,
    showStoredPaymentMethods: config?.showStoredPaymentMethods ?? true,
    showRemovePaymentMethodButton: true,
    onDisableStoredPaymentMethod: config?.onDisableStoredPaymentMethod,
    onReady: config?.onReady,
    onSelect: (uiElement) => {
      config?.onSelect?.(uiElement.type as 'card' | 'ach');
    }
  };

  const component = checkout
    .create('dropin', dropinElementProps)
    .mount(domNode);

  return component;
};

export const generateIdempotencyKey = (): string => {
  return uuidv4();
};
