import {
  BrandType,
  Button,
  useNotifications
} from '@appliedsystems/applied-design-system';
import { LocaleCode } from '@appliedsystems/payments-core';
import { useQuery } from '@tanstack/react-query';
import React from 'react';

// TODO: Add this to core once we figure out how

const REFETCH_INTERVAL = 300000; // 5 minutes

const getAlertCopy = (locale: LocaleCode): string => {
  switch (locale) {
    case LocaleCode.fr_CA:
      return "Une version plus récente d'Applied Pay a été détectée. Pour obtenir la version la plus à jour, cliquez sur actualiser.";
    case LocaleCode.en_US:
    case LocaleCode.en_GB:
    case LocaleCode.en_CA:
    default:
      return 'A newer version of Applied Pay has been detected. To get the most up to date version click Refresh.';
  }
};

const getRefreshButtonText = (locale: LocaleCode): string => {
  switch (locale) {
    case LocaleCode.fr_CA:
      return 'Actualiser';
    case LocaleCode.en_US:
    case LocaleCode.en_GB:
    case LocaleCode.en_CA:
    default:
      return 'Refresh';
  }
};

const refreshPage = () => {
  if ('caches' in window) {
    caches.keys().then((names) => {
      // Delete all the cache files
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  }
  window.location.reload();
};

export const ClientVersionChecker = ({
  cachedVersion,
  locale
}: {
  cachedVersion: string;
  locale: LocaleCode;
}) => {
  const { addNotification } = useNotifications();

  const [notificationShown, setNotificationShown] = React.useState(false);

  const RefreshNotification = (
    <div className="version-alert-content">
      {getAlertCopy(locale)}
      <br />
      <br />
      <Button onClick={refreshPage}>{getRefreshButtonText(locale)}</Button>
    </div>
  );

  useQuery(
    ['version-check', cachedVersion],
    async () => {
      try {
        const metaFile = await fetch('./meta.json');
        const metaJson = await metaFile.json();
        const latestVersion = metaJson?.version;

        if (cachedVersion !== latestVersion && !notificationShown) {
          addNotification({
            type: BrandType.Warning,
            content: RefreshNotification
          });

          setNotificationShown(true);
        }
      } catch (err) {
        console.error(`Error fetching version file: ${err}`);
      }

      return null;
    },
    {
      refetchInterval: REFETCH_INTERVAL
    }
  );

  return null;
};
