import {
  HppConfig,
  MerchantAccountSettingsPayload
} from '@appliedsystems/payments-core';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import tinycolor from 'tinycolor2';
import { ApiClient } from '../api/ApiClient';
import { useCheckoutStore } from '../components/CheckoutV2/useCheckoutStore';
import { ErrorMessage } from '../components/ErrorAlert/ErrorAlert';
import { Locale } from '../store/Locale';
import { adjustTextColorForReadability, getSubdomain } from '../util/util';
import { useURLQuery } from './useURLQuery';

function setBrandColors(data: MerchantAccountSettingsPayload) {
  if (data.primaryColor && data.primaryTextColor) {
    let primaryBackgroundColor = data.primaryColor;
    let primaryTextColor = data.primaryTextColor;
    let tertiaryTextColor = data.primaryColor;
    let pageBackgroundColor = '#f6fafa'; // background color of the page
    // Access the root element
    const root = document.documentElement;

    const isReadable = tinycolor.isReadable(
      primaryTextColor,
      primaryBackgroundColor,
      // least strict level for readability based on WCAG2 guidelines.
      {
        level: 'AA',
        size: 'large'
      }
    );

    if (!isReadable) {
      // Adjust primary and tertiary text colors for readability
      primaryTextColor = adjustTextColorForReadability(
        primaryTextColor,
        primaryBackgroundColor
      );
      tertiaryTextColor = adjustTextColorForReadability(
        tertiaryTextColor,
        pageBackgroundColor
      );
    }

    // Set the CSS variables
    root.style.setProperty('--brand-primary-color', primaryBackgroundColor);
    root.style.setProperty('--brand-text-color', primaryTextColor);
    root.style.setProperty('--brand-tertiary-text-color', tertiaryTextColor);

    // Set the border color if primary background color and page background color are too close
    if (
      tinycolor.readability(primaryBackgroundColor, pageBackgroundColor) < 2
    ) {
      root.style.setProperty('--brand-primary-border-color', primaryTextColor);
    }
  }
}

export function useAgencyDetail() {
  const subdomain = getSubdomain();
  const query = useURLQuery();
  const { pathname } = useLocation();
  const { locale } = Locale.useContainer();
  const { agencyToken } = useCheckoutStore();

  const hppToken = useMemo(
    () => query.get('token') || agencyToken,
    [agencyToken, query]
  );

  const {
    data: response,
    isLoading,
    error
  } = useQuery({
    queryKey: ['useAgencyDetails', hppToken, subdomain],
    queryFn: () => {
      return ApiClient.getInstance().getHppAgencyDetail(hppToken, subdomain);
    },
    staleTime: 1000 * 60 * 5,
    enabled: pathname.includes('v2') // Do not call this on v1
  });

  const { errorMessage, data } = useMemo<{
    errorMessage?: ErrorMessage;
    data?: MerchantAccountSettingsPayload;
  }>(() => {
    if (error) {
      console.error('Error loading agency details', error);
      return { errorMessage: ['FETCH_AGENCY_DETAIL_FAILED_UNKNOWN'] };
    }
    if (!response) return {};

    if (response.status === 'ok') return { data: response.data! };

    if (response.type === 'network' || response.status > 500)
      return { errorMessage: ['FETCH_AGENCY_DETAIL_FAILED_NETWORK'] };
    else if (response.status === 500)
      return {
        errorMessage: [
          'FETCH_AGENCY_DETAIL_FAILED_INTERNAL',
          { id: response.traceId }
        ]
      };
    else
      return {
        errorMessage: [
          'FETCH_AGENCY_DETAIL_FAILED_UNKNOWN',
          { id: response.traceId }
        ]
      };
  }, [response, error]);

  const hppConfig = useMemo<HppConfig>(() => {
    const defaultValues: HppConfig =
      response?.status === 'ok' && response.data?.config
        ? response.data.config
        : {
            showLogo: true,
            showTitle: true,
            showCopyright: true,
            locale
          };

    const urlConfig = query.get('c');
    if (!urlConfig) {
      return defaultValues;
    }

    try {
      const plainText = Buffer.from(urlConfig, 'base64').toString();
      return { ...defaultValues, ...JSON.parse(plainText) };
    } catch (e) {
      return defaultValues;
    }
  }, [locale, query, response]);

  useEffect(() => {
    if (data) setBrandColors(data);
  }, [data]);

  return {
    data,
    tenantId: data?.tenantId,
    hppConfig,
    isLoading,
    errorMessage
  };
}
