import {
  ADSAnyObject,
  BrandType,
  Button,
  Col,
  FieldSkeleton,
  Flex,
  Form,
  FormMethods,
  H2,
  InlineAlert,
  object,
  Row,
  string,
  TextField
} from '@appliedsystems/applied-design-system';
import {
  EpicInvoice,
  LocaleCode,
  removeNullProperties
} from '@appliedsystems/payments-core';
import { datadogLogs } from '@datadog/browser-logs';
import classNames from 'classnames';
import React from 'react';
import { ApiClient } from '../../../api/ApiClient';
import { usePaymentsTranslation } from '../../../hooks/usePaymentsTranslation';
import { getLocale, Translation } from '../../../localization/translations';
import { useAccountManagementStore } from '../../../store/AccountManagement';
import classes from '../HostedPaymentPage.module.scss';
import { InvoiceDataGrid, InvoiceGroup } from './InvoiceDataGrid';
import { useWorkflowState } from './WorkflowContext';

export type InvoiceItem = {
  id: string;
  amount: {
    units: number;
    partialUnits: number;
  };
};

export const SkeletonForm = () => (
  <>
    <Flex>
      <Row>
        <Col xs={6}>
          <FieldSkeleton />
        </Col>
        <Col xs={6}>
          <FieldSkeleton />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <FieldSkeleton />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <FieldSkeleton />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <FieldSkeleton />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <FieldSkeleton />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <FieldSkeleton />
        </Col>
      </Row>
    </Flex>
  </>
);

type Props = {
  hppToken: string | null;
  locale: LocaleCode;
  onSuccess: (
    values: PayByInvoiceSchema,
    invoices: InvoiceGroup[],
    items: InvoiceItem[],
    clientId: string
  ) => void;
  readonly: boolean;
  initialLoadingState: boolean;
  lookupAllInvoices: boolean;
};

export type PayByInvoiceSchema = {
  firstName: string;
  lastName: string;
  businessName?: string;
  email: string;
  accountNumber: string;
  invoiceNumber: string;
  postalCode: string;
};

export const makeSchema = (
  defaultValues: any,
  t: (key: keyof Translation) => string
) =>
  object({
    firstName: string()
      .default(defaultValues.firstName)
      .required(t('ERROR_FIRST_NAME_REQUIRED')),
    lastName: string()
      .default(defaultValues.lastName)
      .required(t('ERROR_LAST_NAME_REQUIRED')),
    businessName: string().default(defaultValues.businessName).optional(),
    email: string()
      .email(t('ERROR_EMAIL_INVALID'))
      .default(defaultValues.email)
      .required(t('ERROR_EMAIL_REQUIRED')),
    accountNumber: string()
      .default(defaultValues.accountNumber) // for testing in development: CROMCON-01
      .required(t('ERROR_ACCOUNT_NUMBER_REQUIRED')),
    invoiceNumber: string()
      .default(defaultValues.invoiceNumber) // for testing in development: 806
      .required(t('ERROR_INVOICE_NUMBER_REQUIRED')),
    postalCode: string()
      .default(defaultValues.postalCode) // for testing in development: 60443
      .required(t('ERROR_POSTAL_CODE_REQUIRED'))
  });

export const PayByInvoice = ({
  hppToken,
  locale,
  onSuccess,
  readonly,
  initialLoadingState,
  lookupAllInvoices
}: Props) => {
  const { t } = usePaymentsTranslation();
  const sessionId = datadogLogs.getInternalContext()?.session_id;
  const { customerUser } = useAccountManagementStore();
  const { workflowState, handleADSInputEvent } = useWorkflowState();
  const [loading, setLoading] = React.useState(initialLoadingState);
  const [error, setError] = React.useState<string | null>(null);
  const [clientId, setClientId] = React.useState<string | null>(null);
  const [matchingInvoices, setMatchingInvoices] = React.useState<
    EpicInvoice[] | null
  >(null);
  const [selectedInvoices, setSelectedInvoices] =
    React.useState<InvoiceGroup[]>();
  const [formValues, setFormValues] = React.useState<PayByInvoiceSchema>();
  const formSchema = React.useRef<ADSAnyObject>(
    makeSchema(
      {
        ...workflowState,
        ...(customerUser && {
          firstName: customerUser.firstName,
          lastName: customerUser.lastName,
          email: customerUser.email,
          accountNumber: customerUser.accountNumber
        }),
        // for testing in development:
        // firstName: 'Jeff',
        // lastName: 'Smith',
        // email: 'e@jeffodle.com',
        // accountNumber: 'CROMCON-01',
        // invoiceNumber: '806',
        // postalCode: '60443',
        locale: getLocale(locale)
      },
      t
    )
  );

  const handleLookupSuccess = (data: any, _form: FormMethods<any> | null) => {
    if (!hppToken) {
      return;
    }
    setLoading(true);
    setError(null);
    setFormValues(data);
    ApiClient.getInstance()
      .epicInvoiceLookup(
        hppToken,
        {
          accountCode: data.accountNumber,
          invoiceNumber: data.invoiceNumber,
          postalCode: data.postalCode
        },
        lookupAllInvoices
      )
      .then((result) => {
        if (result?.status === 'ok') {
          if (
            result.data?.status === 'valid' &&
            result.data?.invoices?.length > 0
          ) {
            setClientId(result.data.clientId);
            setMatchingInvoices(result.data.invoices);
          } else {
            setError(
              result.data?.status === 'rate-limit-exceeded'
                ? t('ERROR_HPP_VALIDATION_EXCEEDED_NO_BYPASS')
                : t('ERROR_HPP_VALIDATION')
            );
          }
        } else {
          console.error(
            'Got unexpected status while validating epic invoice: %s',
            result.status
          );
          if (result.type === 'network')
            setError(t('ERROR_HPP_VALIDATION_NETWORK'));
          else if (result.status >= 400 && result.status < 500)
            setError(
              t('ERROR_HPP_VALIDATION_UNKNOWN', undefined, {
                id: result.traceId
              } as any)
            );
          else
            setError(
              t('ERROR_HPP_VALIDATION_INTERNAL', undefined, {
                id: result.traceId
              } as any)
            );
        }
      })
      .catch((_error) => {
        console.error('error looking up epic invoice', _error);
        setError(
          t('ERROR_HPP_VALIDATION_UNKNOWN', undefined, { id: sessionId } as any)
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleInvoiceChange = (invoices: InvoiceGroup[]) => {
    setSelectedInvoices(invoices);
  };

  const handleSubmit = () => {
    if (!formValues || !selectedInvoices || !clientId) {
      setError(t('ERROR_HPP_VALIDATION'));
      return;
    }
    const invoiceItems =
      matchingInvoices
        ?.filter((e) =>
          selectedInvoices.find((i) => i.invoiceNumber === e.invoiceNumber)
        )
        .map((e) => ({
          id: e.transactionId,
          amount: {
            units: +e.amountDue.units,
            partialUnits: +e.amountDue.partialUnits
          }
        })) || [];

    onSuccess?.(
      removeNullProperties(formValues),
      selectedInvoices,
      invoiceItems,
      clientId
    );
  };

  return (
    <Form schema={formSchema.current} onSubmit={handleLookupSuccess}>
      <H2 className="mt-100 mb-0">
        {matchingInvoices && matchingInvoices.length > 0
          ? t('INVOICE_SELECTION')
          : t('ACCOUNT_INFORMATION')}
      </H2>
      <p>
        {matchingInvoices && matchingInvoices.length > 0
          ? t('SELECT_INVOICES_TO_PAY')
          : t('ENTER_YOUR_ACCOUNT_DETAILS')}
      </p>
      {error && <InlineAlert type={BrandType.Error}>{error}</InlineAlert>}
      {!matchingInvoices && (
        <>
          <div className={classNames(classes.form, classes.topForm, 'mt-50')}>
            {loading || !hppToken ? (
              <SkeletonForm />
            ) : (
              <Flex>
                <Row className={classes.gapFix}>
                  <Col xs={12} sm={6}>
                    <div style={{ width: '100%' }}>
                      <TextField
                        name="firstName"
                        label={t('FIRST_NAME')}
                        testId="firstNameTest"
                        disabled={readonly}
                        onChange={handleADSInputEvent}
                      />
                    </div>
                  </Col>
                  <Col xs={12} sm={6}>
                    <div style={{ width: '100%' }}>
                      <TextField
                        name="lastName"
                        label={t('LAST_NAME')}
                        testId="lastNameTest"
                        disabled={readonly}
                        onChange={handleADSInputEvent}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className={classes.gapFix}>
                  <Col xs={12}>
                    <div style={{ width: '100%' }}>
                      <TextField
                        name="businessName"
                        label={t('BUSINESS_NAME')}
                        testId="businessNameTest"
                        disabled={readonly}
                        onChange={handleADSInputEvent}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className={classes.gapFix}>
                  <Col xs={12}>
                    <div style={{ width: '100%' }}>
                      <TextField
                        name="email"
                        label={t('EMAIL')}
                        testId="emailTest"
                        disabled={readonly}
                        onChange={handleADSInputEvent}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className={classes.gapFix}>
                  <Col xs={12}>
                    <div style={{ width: '100%' }}>
                      <TextField
                        name="accountNumber"
                        label={t('ACCOUNT_NUMBER')}
                        testId="accountNumberTest"
                        disabled={readonly}
                        onChange={handleADSInputEvent}
                        helpText={t('ACCOUNT_NUMBER_TOOLTIP')}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className={classes.gapFix}>
                  <Col xs={12}>
                    <div style={{ width: '100%' }}>
                      <TextField
                        name="invoiceNumber"
                        label={t('INVOICE_NUMBER')}
                        testId="invoiceNumberTest"
                        disabled={readonly}
                        onChange={handleADSInputEvent}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className={classes.gapFix}>
                  <Col xs={12}>
                    <div style={{ width: '100%' }}>
                      <TextField
                        name="postalCode"
                        label={t('POSTAL_CODE')}
                        testId="postalCodeTest"
                        disabled={readonly}
                        onChange={handleADSInputEvent}
                      />
                    </div>
                  </Col>
                </Row>
              </Flex>
            )}
          </div>
          <Row className={classes.gapFix}>
            <Col xs={12} className="text-right">
              <Button
                submit
                type="primary"
                icon={loading ? 'SpinnerIcon' : undefined}
                disabled={loading}
                testId="lookupInvoicesButton"
              >
                {t('NEXT')}
              </Button>
            </Col>
          </Row>
        </>
      )}
      {matchingInvoices && matchingInvoices.length > 0 && (
        <>
          <div
            className={classNames(
              classes.form,
              classes.topForm,
              'mt-50',
              'p-0'
            )}
          >
            <InvoiceDataGrid
              invoices={matchingInvoices}
              locale={locale}
              onChange={handleInvoiceChange}
            />
          </div>
          <div className="text-right">
            <Button
              onClick={() => setMatchingInvoices(null)}
              icon={loading ? 'SpinnerIcon' : undefined}
              className="mr-50"
            >
              {t('BACK')}
            </Button>
            <Button
              onClick={handleSubmit}
              type="primary"
              icon={loading ? 'SpinnerIcon' : undefined}
              testId="submitButton"
              disabled={
                !selectedInvoices || selectedInvoices.length === 0 || loading
              }
            >
              {t('NEXT')}
            </Button>
          </div>
        </>
      )}
    </Form>
  );
};
