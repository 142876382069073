import { useTranslation as useAppliedTranslation } from '@appliedsystems/applied-design-system';
import { useCallback } from 'react';
import { Translation } from '../localization/translations';

type Key =
  | keyof Translation
  | TemplateStringsArray
  | (keyof Translation | TemplateStringsArray)[];

export const usePaymentsTranslation = () => {
  const { t } = useAppliedTranslation();
  return {
    t: useCallback(
      (
        key: Key | string, // FIXME this should only allow keyof Translation
        defaultValue?: Parameters<typeof t>[1],
        options?: Parameters<typeof t>[2]
      ) => t(key, defaultValue, options),
      [t]
    )
  };
};

export type TranslateFunc = ReturnType<typeof usePaymentsTranslation>['t'];
export type TranslationKey = Key;
