import {
  Col,
  CurrencyField,
  Flex,
  Row,
  TextField,
  useFormContext
} from '@appliedsystems/applied-design-system';
import { LocaleCode } from '@appliedsystems/payments-core';
import React, { useEffect } from 'react';
import { usePaymentsTranslation } from '../../hooks/usePaymentsTranslation';
import classes from './HostedPaymentPage.module.scss';

type Props = {
  locale: LocaleCode;
  setValidationHandler: any;
  setAmount?: (amount: number) => void;
  readonly: boolean;
};

export const FormFields = ({
  locale,
  setValidationHandler,
  setAmount,
  readonly
}: Props) => {
  const { t } = usePaymentsTranslation();
  const { handleSubmit, useWatch } = useFormContext();
  const { amount } = useWatch();

  const { accountNumber } = useWatch();

  useEffect(() => {
    const _handleSubmit = handleSubmit;
    setValidationHandler(
      () => (onSuccess: any, onError: any) => _handleSubmit(onSuccess, onError)
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setAmount?.(amount);
  }, [setAmount, amount]);

  return (
    <>
      <Flex>
        <Row className={classes.gapFix}>
          <Col xs={12} sm={6}>
            <div style={{ width: '100%' }}>
              <TextField
                name="firstName"
                label={t('FIRST_NAME')}
                testId="firstNameTest"
                disabled={readonly}
              />
            </div>
          </Col>
          <Col xs={12} sm={6}>
            <div style={{ width: '100%' }}>
              <TextField
                name="lastName"
                label={t('LAST_NAME')}
                testId="lastNameTest"
                disabled={readonly}
              />
            </div>
          </Col>
        </Row>
        <Row className={classes.gapFix}>
          <Col xs={12}>
            <div style={{ width: '100%' }}>
              <TextField
                name="businessName"
                label={t('BUSINESS_NAME')}
                testId="businessNameTest"
                disabled={readonly}
              />
            </div>
          </Col>
        </Row>
        <Row className={classes.gapFix}>
          <Col xs={12}>
            <div style={{ width: '100%' }}>
              <TextField
                name="email"
                label={t('EMAIL')}
                testId="emailTest"
                disabled={readonly}
              />
            </div>
          </Col>
        </Row>
        <Row className={classes.gapFix}>
          {accountNumber && (
            <Col xs={12}>
              <div style={{ width: '100%' }}>
                <TextField
                  name="accountNumber"
                  label={t('ACCOUNT_NUMBER')}
                  testId="accountNumberTest"
                  disabled={true}
                />
              </div>
            </Col>
          )}
        </Row>
        <Row className={classes.gapFix}>
          <Col xs={12} sm={6}>
            <div style={{ width: '100%' }}>
              <TextField
                name="policyNumber"
                label={t('POLICY_NUMBER')}
                testId="policyNumberTest"
                disabled={readonly}
              />
            </div>
          </Col>
          <Col xs={12} sm={6}>
            <div style={{ width: '100%' }}>
              <TextField
                name="invoiceNumber"
                label={t('INVOICE_NUMBER')}
                testId="invoiceNumberTest"
                disabled={readonly}
              />
            </div>
          </Col>
        </Row>
        <Row className={classes.gapFix}>
          <Col xs={12}>
            <div style={{ width: '100%' }}>
              <TextField
                name="paymentDescription"
                label={t('PAYMENT_DESCRIPTION')}
                testId="paymentDescriptionTest"
                required
                disabled={readonly}
              />
            </div>
          </Col>
        </Row>
        <Row className={classes.gapFix}>
          <Col xs={12}>
            <div style={{ width: '100%' }}>
              <CurrencyField
                name="amount"
                label={t('AMOUNT')}
                testId="amountTest"
                locale={locale}
                disabled={readonly}
              />
            </div>
          </Col>
        </Row>
      </Flex>
    </>
  );
};
