import {
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  object,
  string,
  TextField
} from '@appliedsystems/applied-design-system';
import {
  StoredPaymentMethod,
  UpdateStoredPaymentMethodRequest
} from '@appliedsystems/payments-core';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useMemo } from 'react';
import { ApiClient } from '../../../../api/ApiClient';
import { usePaymentsTranslation } from '../../../../hooks/usePaymentsTranslation';

const getEditPaymentMethodSchema = (defaultValues: {
  paymentMethodNickname: string;
}) =>
  object({
    paymentMethodNickname: string().default(defaultValues.paymentMethodNickname)
  });

type Props = {
  onClose: () => void;
  storedPaymentMethod?: StoredPaymentMethod;
};

export const EditPaymentMethodModal = ({
  onClose,
  storedPaymentMethod
}: Props) => {
  const queryClient = useQueryClient();
  const { t } = usePaymentsTranslation();
  const updatePaymentMethodMutation = useMutation({
    mutationKey: [
      'updatePaymentMethod',
      storedPaymentMethod?.recurringDetailReference
    ],
    mutationFn: (data: UpdateStoredPaymentMethodRequest) => {
      if (!storedPaymentMethod?.recurringDetailReference) {
        throw new Error('No recurringDetailReference');
      }

      return ApiClient.getInstance().updateStoredPaymentMethods(
        storedPaymentMethod?.recurringDetailReference,
        data
      );
    },
    onSuccess: () => {
      onClose();
      queryClient.invalidateQueries(['paymentMethods']);
    }
  });

  const schema = useMemo(
    () =>
      getEditPaymentMethodSchema({
        paymentMethodNickname: storedPaymentMethod?.paymentMethodNickname ?? ''
      }),
    [storedPaymentMethod]
  );

  return (
    <Modal
      open={!!storedPaymentMethod}
      onClose={onClose}
      title={t('EDIT_STORED_PAYMENT_METHOD')}
    >
      <Form
        schema={schema}
        onSubmit={(values) => updatePaymentMethodMutation.mutate(values)}
      >
        <ModalBody>
          <div style={{ minWidth: '350px' }}>
            <TextField
              name="paymentMethodNickname"
              label={t('PAYMENT_METHOD_NICKNAME')}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button type="tertiary" onClick={onClose}>
            {t('CANCEL')}
          </Button>
          <Button
            isLoading={updatePaymentMethodMutation.isLoading}
            type="primary"
            className="ml-100"
            submit
            testId="saveButton"
          >
            {t('SAVE')}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};
