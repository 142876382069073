import { Accordion, Col, Row } from '@appliedsystems/applied-design-system';
import { PaymentMethod } from '@appliedsystems/payments-core';
import React, { useEffect, useState } from 'react';
import { useHppAccordion } from '../../hooks/useAccordionState';
import { useIsEligibleForFlowV2 } from '../../hooks/useIsEligibleforHppCheckoutUi';
import { usePaymentsTranslation } from '../../hooks/usePaymentsTranslation';
import { useAgencyDetailsStore } from '../../store/AgencyDetail';
import { AccountInformationForm } from '../AccountInformationForm/AccountInformationForm';
import { ConfirmationPage } from '../ConfirmationPage/ConfirmationPage';
import { ErrorAlert } from '../ErrorAlert/ErrorAlert';
import { Layout } from '../Layout/Layout';
import { PaymentMethodContainer } from '../PaymentMethodContainer';
import { PolicyInformationForm } from '../PolicyInformationForm/PolicyInformationForm';
import { SummaryCard } from '../SummaryCard/SummaryCard';
import { HppStep, PayBySelection } from './enums';
import classes from './HostedPaymentPageContainer.module.scss';
import { useHppDataStore } from './useHppData';
import { useHppSessionStore } from './useHppSession';

const { ACCOUNT_INFORMATION, POLICY_INFORMATION, PAYMENT_METHOD } = HppStep;
const hppSteps = [ACCOUNT_INFORMATION, POLICY_INFORMATION, PAYMENT_METHOD];

export const HostedPaymentContainer = (): React.ReactElement => {
  // Hooks
  const { t } = usePaymentsTranslation();
  const { data: agencyDetails, errorMessage } = useAgencyDetailsStore();
  const { setHppSession } = useHppSessionStore();
  const {
    setPaymentMethodConfig,
    setRetrievedInvoices,
    setSelectedInvoices,
    setHppData
  } = useHppDataStore();

  useIsEligibleForFlowV2(agencyDetails?.tenantId, '/flow/hpp');

  // Accordion stuff
  const { accordionStates, updateAccordions, checkDependencies } =
    useHppAccordion<
      typeof HppStep,
      'ACCOUNT_INFORMATION' | 'POLICY_INFORMATION' | 'PAYMENT_METHOD'
    >({
      ACCOUNT_INFORMATION: {
        isOpen: true,
        isValid: false,
        dependsOn: []
      },
      POLICY_INFORMATION: {
        isOpen: false,
        isValid: false,
        dependsOn: [HppStep.ACCOUNT_INFORMATION]
      },
      PAYMENT_METHOD: {
        isOpen: false,
        isValid: false,
        dependsOn: [HppStep.ACCOUNT_INFORMATION, HppStep.POLICY_INFORMATION]
      }
    });

  // Effects
  useEffect(() => {
    setPaymentMethodConfig({
      [PaymentMethod.Ach]: {
        allowed: agencyDetails?.achEnabled ?? false,
        fee: agencyDetails?.achFeeAmount ?? 0.0
      },
      [PaymentMethod.Card]: {
        allowed: agencyDetails?.creditEnabled ?? false,
        fee: agencyDetails?.creditFeePercent ?? 0.0
      }
    });
  }, [agencyDetails, setPaymentMethodConfig]);

  const [paymentSucceeded, setPaymentSucceeded] = useState(false);

  // Since all accordions behave similarly, run a function
  // to standardize what's passed when the components render
  const renderHppStep = (
    hppStep: HppStep,
    componentToRender: React.ReactNode
  ) => {
    const stepIndex = hppSteps.findIndex((e) => e === hppStep);

    return (
      <div
        className={`${classes.accordion} ${
          accordionStates[hppStep].isValid ? classes.accordionSuccess : ''
        }`}
        key={hppStep}
      >
        <Accordion
          title={`${stepIndex + 1}. ${t(hppStep)}`}
          open={accordionStates[hppStep]?.isOpen && !!agencyDetails}
          onOpenChange={(nextState) => {
            updateAccordions({
              [hppStep]: {
                isOpen:
                  nextState === false ? nextState : checkDependencies(hppStep),
                isValid: accordionStates[hppStep].isValid
              }
            });
          }}
          testId={`${hppStep.toLowerCase()}-accordion`}
        >
          <Row className={classes.accordionContent}>
            <Col xs={12}>{componentToRender}</Col>
          </Row>
        </Accordion>
      </div>
    );
  };

  // Passing the components as the second parameter
  // directly without abstracting it into a function
  // guarantees a memory reference to the component
  const accordions = (
    <>
      {/* Step 1. Determine payment workflow, collect and validate user info */}
      {renderHppStep(
        ACCOUNT_INFORMATION,
        <AccountInformationForm
          onDataValidated={() => {
            updateAccordions({
              [ACCOUNT_INFORMATION]: {
                isValid: true,
                isOpen: false
              },
              [POLICY_INFORMATION]: {
                isValid: false,
                isOpen: true
              }
            });
          }}
          onDataChange={() => {
            updateAccordions({
              [ACCOUNT_INFORMATION]: {
                isValid: false,
                isOpen: true
              }
            });

            setHppData({
              paymentFee: 0,
              paymentMethod: undefined,
              paymentTotal: 0,
              paymentAmount: 0
            });

            setRetrievedInvoices([]);
            setSelectedInvoices([]);
            setHppSession(undefined);
          }}
        />
      )}
      {/* Step 2. Gather information about the payment for either single amount or invoice */}
      {renderHppStep(
        POLICY_INFORMATION,
        <PolicyInformationForm
          onBack={() => {
            updateAccordions({
              [ACCOUNT_INFORMATION]: {
                isOpen: true
              },
              [POLICY_INFORMATION]: {
                isOpen: false
              }
            });
          }}
          onDataValidated={() => {
            updateAccordions({
              [POLICY_INFORMATION]: {
                isValid: true,
                isOpen: false
              },
              [PAYMENT_METHOD]: {
                isValid: false,
                isOpen: true
              }
            });
          }}
          onDataChange={() => {
            updateAccordions({
              [POLICY_INFORMATION]: {
                isValid: false
              }
            });

            setHppData({
              paymentFee: 0,
              paymentMethod: undefined,
              paymentTotal: 0
            });
          }}
        />
      )}
      {renderHppStep(
        PAYMENT_METHOD,
        <PaymentMethodContainer
          onPaymentSuccess={() => setPaymentSucceeded(true)}
          onBack={() => {
            updateAccordions({
              [POLICY_INFORMATION]: {
                isOpen: true
              },
              [PAYMENT_METHOD]: {
                isOpen: false
              }
            });
          }}
        />
      )}
    </>
  );

  const orderSummary = <SummaryCard isConfirmation={false} />;

  // TODO: link merchant name
  const confirmationPage = (
    <ConfirmationPage merchantName={agencyDetails?.name} />
  );

  return (
    <Layout
      accordions={
        errorMessage ? <ErrorAlert errorMessage={errorMessage} /> : accordions
      }
      orderSummary={orderSummary}
      showConfirmationComponent={paymentSucceeded}
      confirmationComponent={confirmationPage}
    />
  );
};
export { PayBySelection };
