// This is no longer a custom hook that has a dependency on another hook

import {
  HppSingleInvoiceValidationMode,
  MerchantAccountSettingsPayload,
  Workflow
} from '@appliedsystems/payments-core';
import { PayBySelection } from '../components/HostedPaymentPageContainer/enums';

// but rather a straightforward helper function that returns a Workflow
export function getWorkflow(
  payBy: PayBySelection,
  agencyDetails:
    | Pick<
        MerchantAccountSettingsPayload,
        'singleInvoiceValidationMode' | 'multiInvoiceEnabled'
      >
    | undefined
): Workflow {
  const singleInvoiceValidationMode =
    agencyDetails?.singleInvoiceValidationMode;
  const multiInvoiceEnabled = agencyDetails?.multiInvoiceEnabled;

  if (payBy === PayBySelection.AMOUNT) {
    switch (singleInvoiceValidationMode) {
      case HppSingleInvoiceValidationMode.BYPASS_ENABLED:
        return Workflow.SingleAmountWithOptionalBypass; // 2.75
      case HppSingleInvoiceValidationMode.RATE_LIMIT_BYPASS_ONLY:
        return Workflow.SingleAmountWithRateLimitBypass; // 2.5
      case HppSingleInvoiceValidationMode.VALIDATION_REQUIRED:
        return Workflow.SingleAmount; // 2
      default:
        return Workflow.Default; // 1
    }
  }

  if (payBy === PayBySelection.INVOICE) {
    return multiInvoiceEnabled
      ? Workflow.MultiInvoice // 4
      : Workflow.SingleInvoice; // 3
  }

  return Workflow.Default; // 1
}
