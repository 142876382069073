import { Button, Flex } from '@appliedsystems/applied-design-system';
import { LocaleCode } from '@appliedsystems/payments-core';
import React from 'react';
import { usePaymentsTranslation } from '../../hooks/usePaymentsTranslation';
import { useAccountManagementStore } from '../../store/AccountManagement';
import { Locale } from '../../store/Locale';
import styles from './PaymentHeader.module.scss';

type Props = {
  paymentType: 'hpp' | 'checkout';
};

export const PaymentHeader = ({ paymentType }: Props) => {
  const { t } = usePaymentsTranslation();
  const { locale, setLocale } = Locale.useContainer();
  const {
    createAccountModal,
    manageAccountWindow,
    loginModal,
    logout,
    customerUser
  } = useAccountManagementStore();

  const toggleCanadianLocale = () => {
    const newLocale =
      locale === LocaleCode.en_CA ? LocaleCode.fr_CA : LocaleCode.en_CA;
    setLocale(newLocale);
  };
  const isCanadianLocale = [LocaleCode.en_CA, LocaleCode.fr_CA].includes(
    locale
  );
  const localeToggleButtonLabel =
    locale === LocaleCode.en_CA ? 'Voir en français' : 'View in English';

  return (
    <div className={styles.wrapper}>
      {isCanadianLocale && (
        <Button type="link" className="mr-100" onClick={toggleCanadianLocale}>
          {localeToggleButtonLabel}
        </Button>
      )}
      {customerUser ? (
        <Flex>
          <div>
            <Button
              type="link"
              className="mr-100"
              onClick={manageAccountWindow.open}
            >
              {customerUser.email}
            </Button>
            <Button type="link" onClick={logout}>
              Log out
            </Button>
          </div>
        </Flex>
      ) : (
        <Flex>
          <Button type="link" onClick={loginModal.open}>
            {t('LOG_IN')}
          </Button>
          <Button type="link" onClick={createAccountModal.open}>
            {t('CREATE_ACCOUNT')}
          </Button>
        </Flex>
      )}
    </div>
  );
};
