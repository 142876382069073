import {
  addIsEnabledToFlags,
  flagDefaults,
  IsFlagValuesEnabled
} from '@appliedsystems/payments-core';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ApiClient } from '../api/ApiClient';
import { getSubdomain } from '../util/util';
import { useHppToken } from './useHppToken';
import { useSessionToken } from './useSessionToken';

export const useFeatureFlags = ():
  | (IsFlagValuesEnabled & { isFeatureFlagsLoading: false })
  | (Partial<IsFlagValuesEnabled> & { isFeatureFlagsLoading: true }) => {
  const [flags, setFlags] = useState<IsFlagValuesEnabled>();
  const subdomain = getSubdomain();
  const { hppToken } = useHppToken();
  const { sessionToken } = useSessionToken();
  const { pathname } = useLocation();
  const isCheckoutFlow = pathname.includes('checkout');

  useEffect(() => {
    void (async () => {
      try {
        console.info(
          'loading feature flags for isCheckoutFlow=%s, sessionToken=%s, hppToken=%s, subdomain=%s',
          isCheckoutFlow,
          sessionToken,
          hppToken,
          subdomain
        );
        const res = await (isCheckoutFlow
          ? ApiClient.getInstance(sessionToken!).getFlowFeatureFlags()
          : ApiClient.getInstance().getHppFeatureFlags(hppToken, subdomain));

        if (res.status === 'ok') {
          setFlags(addIsEnabledToFlags(res.data!));
        } else {
          setFlags(addIsEnabledToFlags(flagDefaults));
          console.error('Could not load feature flags', res);
        }
      } catch (err) {
        console.error('Could not load feature flags', err);
        setFlags(addIsEnabledToFlags(flagDefaults));
      }
    })();
  }, [hppToken, sessionToken, subdomain, isCheckoutFlow]);

  return flags
    ? {
        ...flags,
        isFeatureFlagsLoading: false
      }
    : {
        isFeatureFlagsLoading: true
      };
};
