import {
  H4,
  ListItem,
  SimpleList
} from '@appliedsystems/applied-design-system';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { currencyMap } from '../../constants/constants';
import { usePaymentsTranslation } from '../../hooks/usePaymentsTranslation';
import { Locale } from '../../store/Locale';
import { toIntlFormatHelper } from '../../util/getAmountWithFees';
import { PayBySelection } from '../HostedPaymentPageContainer/enums';
import { useHppDataStore } from '../HostedPaymentPageContainer/useHppData';
import classes from './SummaryCard.module.scss';

export const SummaryCard = ({
  isConfirmation
}: {
  isConfirmation: boolean;
}) => {
  const { t } = usePaymentsTranslation();
  const { hppData, selectedInvoices } = useHppDataStore();
  const { locale } = Locale.useContainer();
  const {
    paymentFee,
    paymentAmount,
    payBy,
    paymentDescription,
    paymentMethod
  } = hppData;
  const { pathname } = useLocation();
  const isCheckout = pathname.includes('checkoutv2');

  const paymentAmountDetail = useMemo(() => {
    const currencyCode = currencyMap[locale];
    return {
      fee: toIntlFormatHelper(paymentFee, currencyCode, locale),
      subtotal: toIntlFormatHelper(paymentAmount, currencyCode, locale),
      total: toIntlFormatHelper(hppData.paymentTotal, currencyCode, locale)
    };
  }, [paymentAmount, paymentFee, hppData.paymentTotal, locale]);

  const renderInvoiceSummary = useMemo(() => {
    return selectedInvoices.length ? (
      selectedInvoices.map((invoice) => {
        return (
          <div key={invoice.invoiceNumber}>
            <div className={classes.invoice}>
              <span>
                {t('INVOICE')} {invoice.invoiceNumber}
              </span>
            </div>
            {invoice.invoiceItems.map((item) => (
              <div className={classes.invoiceItem}>
                <div>{item.description}</div>
                <div>
                  {toIntlFormatHelper(
                    item.amountDue,
                    currencyMap[locale],
                    locale
                  )}
                </div>
              </div>
            ))}
          </div>
        );
      })
    ) : (
      <div className={classes.description}>
        <div>{t('DESCRIPTION')}</div>
        <div>-</div>
      </div>
    );
  }, [selectedInvoices, t, locale]);

  const renderInvoiceTotalsSummary = useMemo(
    () =>
      selectedInvoices.length ? (
        selectedInvoices.map((invoice) => {
          return (
            <div key={invoice.invoiceNumber}>
              <div className={classes.invoice}>
                <span>
                  {t('INVOICE')} {invoice.invoiceNumber}
                </span>
                <div>{invoice.amountDue}</div>
              </div>
              {invoice.invoiceItems.map((item) => (
                <div className={classes.invoiceItem}>
                  <div>{item.description}</div>
                </div>
              ))}
            </div>
          );
        })
      ) : (
        <div className={classes.description}>
          <div>{t('DESCRIPTION')}</div>
          <div>-</div>
        </div>
      ),
    [selectedInvoices, t]
  );

  const paymentTotal = useMemo(
    () => (
      <ListItem>
        <div className={classes.listItem}>
          <H4>{t(isConfirmation ? 'TOTAL_PAID' : 'TOTAL_DUE_NOW')}</H4>
          <H4>{paymentAmountDetail.total}</H4>
        </div>
        {isConfirmation && (
          <div className={classes.listItemSubheading}>
            <span>{t('PAYMENT_METHOD')}</span>

            <span className={classes.paymentMethod}>
              {paymentMethod}{' '}
              {hppData.endDigits &&
                t('ENDING_WITH', undefined, {
                  lastFourDigits: hppData.endDigits
                } as any)}
            </span>
          </div>
        )}
      </ListItem>
    ),
    [
      t,
      isConfirmation,
      paymentAmountDetail.total,
      paymentMethod,
      hppData.endDigits
    ]
  );

  const hideDescription = isCheckout && !paymentDescription;

  return (
    <article className={classes.summaryCard}>
      <SimpleList
        title={t(isConfirmation ? 'PAYMENT_SUMMARY' : 'ORDER_SUMMARY')}
      >
        <hr className={classes.line} />
        {payBy === PayBySelection.INVOICE && (
          <ListItem>{renderInvoiceSummary}</ListItem>
        )}
        {payBy !== PayBySelection.INVOICE && !hideDescription && (
          <ListItem>
            {
              <div className={classes.description}>
                <div>{paymentDescription || t('DESCRIPTION')}</div>
                <div>{paymentAmountDetail.subtotal || '-'}</div>
              </div>
            }
          </ListItem>
        )}
        <ListItem>
          <div className={classes.description}>
            <div>{t('SUBTOTAL')}</div>
            <div>{paymentAmountDetail.subtotal || '-'}</div>
          </div>
          {isConfirmation && (
            <div className={classes.listItem}>
              <span>{t('CONVENIENCE_FEE')}</span>
              <span>{paymentAmountDetail.fee}</span>
            </div>
          )}
        </ListItem>
        {isConfirmation && paymentTotal}
      </SimpleList>
      {!isConfirmation && (
        <SimpleList title={t('PAYMENT_SUMMARY')}>
          <hr className={classes.line} />
          <ListItem>
            {payBy !== PayBySelection.INVOICE && !hideDescription && (
              <div className={classes.description}>
                <div>{paymentDescription || t('DESCRIPTION')}</div>
                <div>{paymentAmountDetail.subtotal || '-'}</div>
              </div>
            )}
            {payBy === PayBySelection.INVOICE && renderInvoiceTotalsSummary}
            <div className={classes.listItem}>
              <span>{t('CONVENIENCE_FEE')}</span>
              <span>{paymentAmountDetail.fee}</span>
            </div>
          </ListItem>
          {paymentTotal}
        </SimpleList>
      )}
    </article>
  );
};
