import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useSessionToken = () => {
  const location = useLocation();

  const [sessionToken, setSessionToken] = React.useState<string | null>(
    location.hash.replace('#session=', '') ||
      window.localStorage.getItem('sessionToken')
  );

  useEffect(() => {
    if (!sessionToken) {
      window.localStorage.removeItem('sessionToken');
      return;
    }
    window.localStorage.setItem('sessionToken', sessionToken);
  }, [location.pathname, sessionToken]);

  const clearSession = () => setSessionToken(null);

  return { sessionToken, clearSession };
};
