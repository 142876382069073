import {
  EpicInvoice,
  PaymentMethod,
  Workflow
} from '@appliedsystems/payments-core';
import { useCallback, useState } from 'react';
import { createContainer } from 'unstated-next';
import { PayBySelection } from './enums';
import { FullInvoiceGroup, HppData, PaymentMethodConfig } from './types';

// Exported so it can be overriden by tests
export const useHppData = () => {
  // Keep this object flat for shallow comparison
  const [hppData, setHppData] = useState<HppData>({
    firstName: '',
    lastName: '',
    userEmail: '',
    businessName: '',
    postalCode: '',
    phoneNumber: '',
    invoiceNumber: '',
    paymentAmount: 0,
    paymentDescription: '',
    policyNumber: '',
    accountCode: '',
    clientId: '',
    payBy: PayBySelection.NONE,
    paymentWorkflow: Workflow.Default,
    paymentMethod: undefined,
    paymentFee: 0,
    paymentTotal: 0,
    paymentMethodBrand: '',
    endDigits: ''
  });

  const [retrievedInvoices, setRetrievedInvoices] = useState<EpicInvoice[]>([]);
  const [selectedInvoices, setSelectedInvoices] = useState<FullInvoiceGroup[]>(
    []
  );

  // Payment method configuration
  const [paymentMethodConfig, setPaymentMethodConfig] =
    useState<PaymentMethodConfig>({
      [PaymentMethod.Ach]: {
        allowed: false,
        fee: 0
      },
      [PaymentMethod.Card]: {
        allowed: false,
        fee: 0
      }
    });

  return {
    retrievedInvoices,
    setRetrievedInvoices,
    selectedInvoices,
    setSelectedInvoices,
    paymentMethodConfig,
    setPaymentMethodConfig,
    hppData,
    setHppData: useCallback(
      (next: Partial<HppData>) => setHppData((prev) => ({ ...prev, ...next })),
      [setHppData]
    )
  };
};

export const HppDataStore = createContainer(useHppData);
export const useHppDataStore = () => HppDataStore.useContainer();
