import { Container, Flex } from '@appliedsystems/applied-design-system';
import React from 'react';
import { Header } from '../Header';
import classes from './Layout.module.scss';

interface LayoutProps {
  accordions: React.ReactNode;
  orderSummary: React.ReactNode;
  confirmationComponent: JSX.Element;
  showConfirmationComponent: boolean;
}

export const Layout: React.FC<LayoutProps> = ({
  accordions,
  orderSummary,
  confirmationComponent,
  showConfirmationComponent
}) => {
  return (
    <Container className={classes.LayoutContainer}>
      <Flex className="flex-align-center">
        <Header isConfirmationPage={showConfirmationComponent} />
        {showConfirmationComponent ? (
          confirmationComponent
        ) : (
          <Flex className={classes.flexContainer}>
            <div>{accordions}</div>
            <div>{orderSummary}</div>
          </Flex>
        )}
      </Flex>
    </Container>
  );
};
