import { PayEnvVars } from '../environment';

type ConfigKey = keyof PayEnvVars;

export const getConfig = <Key extends ConfigKey>(key: Key): PayEnvVars[Key] => {
  const env =
    process.env.NODE_ENV === 'production'
      ? window.env
      : (process.env as unknown as PayEnvVars);
  if (!(key in env)) console.error('missing env var ' + key);

  return env[key];
};
