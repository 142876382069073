import {
  BrandType,
  Modal,
  useNotifications
} from '@appliedsystems/applied-design-system';
import { ErrorCode } from '@appliedsystems/payments-core';
import React, { useCallback, useState } from 'react';
import { ApiClient } from '../../../api/ApiClient';
import { useCustomerUserRequestVerificationCode } from '../../../hooks/useCustomerUserRequestVerificationCode';
import { usePaymentsTranslation } from '../../../hooks/usePaymentsTranslation';
import { useAccountManagementStore } from '../../../store/AccountManagement';
import { VerificationCodeForm } from '../VerificationCodeForm';
import { CreateAccountForm, CreateAccountFormData } from './CreateAccountForm';

type Props = {
  paymentSessionToken: string;
};

export const CreateAccountModal = ({ paymentSessionToken }: Props) => {
  const { t } = usePaymentsTranslation();
  const { addNotification } = useNotifications();
  const { createAccountModal, loginModal, login } = useAccountManagementStore();
  const { requestVerificationCode } = useCustomerUserRequestVerificationCode();
  const [isEnterCodeStep, setIsEnterCodeStep] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [error, setError] = useState<ErrorCode>();
  const handleCreateAccountFormSubmit = useCallback(
    async (values: CreateAccountFormData) => {
      try {
        setUserEmail(values.email);

        const response = await ApiClient.getInstance(
          paymentSessionToken
        ).createCustomerUserAccount(values);

        if (response.status !== 'ok') {
          if (
            response.additionalDetails?.[0]?.errorCode ===
            ErrorCode.AccountNumberNotValid
          ) {
            addNotification({
              type: BrandType.Error,
              title: t('ERROR'),
              time: '',
              content: t('ERROR_ACCOUNT_NUMBER_NOT_VALID')
            });
            return;
          }

          console.error('Unexpected response status', response.status);
          throw new Error('Unexpected response status');
        }

        addNotification({
          type: BrandType.Success,
          title: t('SUCCESS'),
          time: '',
          content: t('ACCOUNT_CREATED_SUCCESSFULLY')
        });

        setIsEnterCodeStep(true);
      } catch (error) {
        console.log(
          'Failed to create customer user account with an error',
          error
        );

        addNotification({
          type: BrandType.Error,
          title: t('ERROR'),
          time: '',
          content: t('FAILED_TO_CREATE_ACCOUNT')
        });
      }
    },
    [addNotification, paymentSessionToken, t]
  );

  const handleVerificationCodeFormSubmit = useCallback(
    async (verificationCode: string) => {
      try {
        const response = await login(paymentSessionToken, {
          email: userEmail,
          verificationCode
        });

        if (
          response.status !== 'ok' &&
          response.additionalDetails?.[0]?.errorCode ===
            ErrorCode.TooManyAttempts
        ) {
          addNotification({
            type: BrandType.Error,
            title: 'Error',
            content: 'Too many attempts. Please try again a bit later.'
          });
          return;
        }

        if (response.status !== 'ok') {
          if (
            response.additionalDetails?.[0]?.errorCode ===
            ErrorCode.VerificationCodeNotValid
          ) {
            setError(ErrorCode.VerificationCodeNotValid);
            return;
          }

          console.error('Unexpected response status', response.status);
          throw new Error('Unexpected response status');
        }

        createAccountModal.close();
      } catch (error) {
        console.log(
          'Failed to login customer user account with an error',
          error
        );
      }
    },
    [addNotification, createAccountModal, login, paymentSessionToken, userEmail]
  );

  const handleLoginClick = () => {
    createAccountModal.close();
    loginModal.open();
  };

  const handleResendCodeClick = async () => {
    await requestVerificationCode(paymentSessionToken, userEmail);
  };

  const handleBackClick = () => {
    setIsEnterCodeStep(false);
    setUserEmail('');
  };

  return (
    <Modal
      open={createAccountModal.isOpen}
      title={t('CREATE_ACCOUNT')}
      onClose={createAccountModal.close}
    >
      {isEnterCodeStep ? (
        <VerificationCodeForm
          isCreatingAccount
          onSubmit={handleVerificationCodeFormSubmit}
          onBackClick={handleBackClick}
          onResendCode={handleResendCodeClick}
          userEmail={userEmail}
          error={error}
        />
      ) : (
        <CreateAccountForm
          onSubmit={handleCreateAccountFormSubmit}
          onCancel={createAccountModal.close}
          onLoginClick={handleLoginClick}
        />
      )}
    </Modal>
  );
};
