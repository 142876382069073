// organize-imports-ignore
// oneTimeInit needs to be first
import { oneTimeInit } from './oneTimeInit';

import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './components/App/App';
import { makeServer } from './mirage-server';

if (process.env.NODE_ENV === 'development') {
  makeServer({ environment: 'development' });
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

oneTimeInit();
