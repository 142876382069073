import {
  Col,
  Flex,
  Row,
  TextField,
  useFormContext
} from '@appliedsystems/applied-design-system';
import React, { useEffect } from 'react';
import { usePaymentsTranslation } from '../../hooks/usePaymentsTranslation';
import classes from './Checkout.module.scss';

type Props = {
  defaultValues: any;
  setValidationHandler: any;
  readonly: boolean;
};

export const FormFields = ({
  defaultValues,
  setValidationHandler,
  readonly
}: Props) => {
  const { t } = usePaymentsTranslation();
  const { handleSubmit } = useFormContext<{
    firstName: string;
    lastName: string;
    email: string;
    businessName: string;
  }>();

  useEffect(() => {
    const _handleSubmit = handleSubmit;
    setValidationHandler(
      () => (onSuccess: any, onError: any) => _handleSubmit(onSuccess, onError)
    );
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Flex>
        <Row className={classes.gapFix}>
          <Col xs={12} sm={6}>
            <div style={{ width: '100%' }}>
              <TextField
                name="firstName"
                label={t('FIRST_NAME')}
                testId="firstNameTest"
                disabled={readonly}
              />
            </div>
          </Col>
          <Col xs={12} sm={6}>
            <div style={{ width: '100%' }}>
              <TextField
                name="lastName"
                label={t('LAST_NAME')}
                testId="lastNameTest"
                disabled={readonly}
              />
            </div>
          </Col>
        </Row>
        <Row className={classes.gapFix}>
          <Col xs={12}>
            <div style={{ width: '100%' }}>
              <TextField
                name="businessName"
                label={t('BUSINESS_NAME')}
                testId="businessNameTest"
                disabled={readonly}
              />
            </div>
          </Col>
        </Row>
        <Row className={classes.gapFix}>
          <Col xs={12}>
            <div style={{ width: '100%' }}>
              <TextField
                name="email"
                label={t('EMAIL')}
                testId="emailTest"
                disabled={readonly}
              />
            </div>
          </Col>
        </Row>
      </Flex>
    </>
  );
};
