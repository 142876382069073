import { ADSInputEvent } from '@appliedsystems/applied-design-system';
import React from 'react';
import { createContainer } from 'unstated-next';

const _useWorkflowState = (initialState = {}) => {
  const [state, setState] = React.useState(initialState);

  const handleADSInputEvent = (e: ADSInputEvent) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    });
  };

  const clearStateKey = (key: string) => {
    setState({
      ...state,
      [key]: undefined
    });
  };

  return {
    workflowState: state as any,
    setWorkflowState: setState,
    handleADSInputEvent,
    clearStateKey
  };
};

export const WorkflowState = createContainer(_useWorkflowState);

export const useWorkflowState = () => WorkflowState.useContainer();
