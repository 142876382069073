import DropinElement from '@adyen/adyen-web/dist/types/components/Dropin';
import {
  BrandType,
  useNotifications
} from '@appliedsystems/applied-design-system';
import {
  ErrorCode,
  PaymentSessionUpdateRequest,
  PspSessionResponsePayload
} from '@appliedsystems/payments-core';
import { datadogLogs } from '@datadog/browser-logs';
import { Buffer } from 'buffer';
import React, { useRef } from 'react';
import { ApiClient } from '../api/ApiClient';
import { usePaymentsTranslation } from '../hooks/usePaymentsTranslation';
import { Translation } from '../localization/translations';
import { ErrorDetail } from '../types/common';
import {
  AdyenConfiguration,
  generateIdempotencyKey,
  initAdyenCheckout
} from '../util/adyen';

type Action =
  | {
      type: 'getSession';
    }
  | {
      type: 'getSessionSuccess';
      payload: Pick<State, 'sessionDetails'>;
    }
  | {
      type: 'initCheckout';
    }
  | {
      type: 'initCheckoutSuccess';
      payload: Pick<State, 'dropinElement'>;
    }
  | {
      type: 'updatePaymentMethod';
      payload: Pick<State, 'selectedPaymentMethod'>;
    }
  | {
      type: 'payment';
    }
  | {
      type: 'paymentSuccess';
    }
  | {
      type: 'error';
      payload: {
        errorCode: ErrorCode;
        errorDetail?: ErrorDetail | null;
      };
    };

type State = {
  paymentFlowSessionId: string | null;
  isSessionLoading: boolean;
  isCheckoutLoading: boolean;
  paymentSuccess: boolean;
  hasFatalError: boolean;
  errorCode: ErrorCode | null;
  sessionDetails: PspSessionResponsePayload | null;
  dropinElement: DropinElement | null;
  selectedPaymentMethod: 'card' | 'ach' | null;
  errorDetail: ErrorDetail | null;
};

const initialState: State = {
  paymentFlowSessionId: null,
  isSessionLoading: false,
  isCheckoutLoading: false,
  paymentSuccess: false,
  hasFatalError: false,
  errorCode: null,
  sessionDetails: null,
  dropinElement: null,
  selectedPaymentMethod: 'card',
  errorDetail: null
};

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'getSession':
      return {
        ...state,
        sessionDetails: null,
        isSessionLoading: true,
        isCheckoutLoading: false,
        paymentSuccess: false,
        hasFatalError: false
      };
    case 'getSessionSuccess':
      return {
        ...state,
        ...action.payload,
        isSessionLoading: false,
        isCheckoutLoading: false,
        paymentSuccess: false,
        hasFatalError: false
      };
    case 'initCheckout':
      return {
        ...state,
        isSessionLoading: false,
        isCheckoutLoading: true,
        paymentSuccess: false,
        hasFatalError: false
      };
    // deepcode ignore DuplicateCaseBody: todo snyk code quality warning
    case 'initCheckoutSuccess':
      return {
        ...state,
        ...action.payload,
        isSessionLoading: false,
        isCheckoutLoading: false,
        paymentSuccess: false,
        hasFatalError: false
      };
    // deepcode ignore DuplicateCaseBody: todo snyk code quality warning
    case 'updatePaymentMethod':
      return {
        ...state,
        ...action.payload,
        isSessionLoading: false,
        isCheckoutLoading: false,
        paymentSuccess: false,
        hasFatalError: false
      };
    case 'payment':
      return {
        ...state,
        isSessionLoading: false,
        isCheckoutLoading: false,
        paymentSuccess: false,
        hasFatalError: false
      };
    case 'paymentSuccess':
      return {
        ...state,
        isSessionLoading: false,
        isCheckoutLoading: false,
        paymentSuccess: true,
        hasFatalError: false
      };
    case 'error':
      return {
        ...state,
        ...action.payload,
        isSessionLoading: false,
        isCheckoutLoading: false,
        paymentSuccess: false,
        hasFatalError: true
      };
    default:
      throw new Error();
  }
};

export const useCheckoutReducer = (paymentFlowSessionId: string | null) => {
  const [state, dispatch] = React.useReducer(reducer, {
    ...initialState,
    paymentFlowSessionId
  });
  // CurrentState is used to access the latest state in async functions because of react's closure
  // Docs: https://react.dev/learn/state-as-a-snapshot#rendering-takes-a-snapshot-in-time
  const currentState = useRef(state);
  currentState.current = state;

  const { addNotification } = useNotifications();
  const { t } = usePaymentsTranslation();

  const setFatalError = (
    errorCode: ErrorCode,
    errorDetail?: ErrorDetail | null
  ) => dispatch({ type: 'error', payload: { errorCode, errorDetail } });

  const showTemporaryErrorMessage = (
    message: string,
    component?: DropinElement
  ) => {
    component?.setStatus('error');
    addNotification({
      type: BrandType.Error,
      title: t('ERROR_PAYMENT_ERROR'),
      time: '',
      content: message
    });
    setTimeout(() => {
      component?.setStatus('ready');
    }, 2000);
  };
  const showUnknownErrorMessage = (code: number, component?: DropinElement) => {
    showTemporaryErrorMessage(`Unexpected Error (code: c${code})`);
  };

  const getSession = () => {
    if (!state.paymentFlowSessionId) {
      console.error('Payment flow session id is missing');
      setFatalError(ErrorCode.PaymentFlowSessionIdMissing);
      return;
    }
    dispatch({ type: 'getSession' });
    ApiClient.getInstance(state.paymentFlowSessionId)
      .initPspSession()
      .then((response) => {
        if (response.status !== 'ok') {
          const errorCode = response.additionalDetails?.[0]?.errorCode;
          console.error('Failed to init psp session with response', response);
          if (
            errorCode === ErrorCode.PaymentSessionExpired &&
            response.additionalData?.hppLinkExpirationDays
          ) {
            setFatalError(errorCode, {
              paymentSessionExpirationDays:
                response.additionalData.hppLinkExpirationDays
            });
          } else {
            setFatalError(
              errorCode ||
                (response.type === 'network'
                  ? ErrorCode.PaymentFlowSessionFailedNetwork
                  : response.status >= 400 && response.status < 500
                  ? ErrorCode.PaymentFlowSessionFailedUnknown
                  : ErrorCode.PaymentFlowSessionFailedInternal),
              state.paymentFlowSessionId
            );
          }
          return;
        }

        dispatch({
          type: 'getSessionSuccess',
          payload: { sessionDetails: response.data ?? null }
        });
      })
      .catch((err) => {
        console.error('Failed to init psp session with error', err);
        setFatalError(
          ErrorCode.PaymentFlowSessionErrorUnknown,
          state.paymentFlowSessionId
        );
      });
  };

  const initCheckout = (
    domNode: string,
    config: Partial<AdyenConfiguration>
  ) => {
    if (!state.sessionDetails) {
      console.warn('Session details are missing');
      return;
    }

    if (state.dropinElement) {
      state.dropinElement.unmount();
    }

    initAdyenCheckout(domNode, state.sessionDetails, t, config)
      .then((component) => {
        dispatch({
          type: 'initCheckoutSuccess',
          payload: { dropinElement: component }
        });
      })
      .catch((err) => {
        console.error('Failed to init checkout with an error:', err);
        setFatalError(
          ErrorCode.InitAdyenCheckoutFailed,
          state.paymentFlowSessionId
        );
      });
  };

  const updateSession = async (
    data: Partial<Omit<PaymentSessionUpdateRequest, 'sessionToken'>>
  ) => {
    if (!state.paymentFlowSessionId) {
      console.error('Payment flow session id is missing');
      setFatalError(ErrorCode.PaymentFlowSessionIdMissing);
      return false;
    }
    try {
      const response = await ApiClient.getInstance(
        state.paymentFlowSessionId
      ).updatePaymentSession({
        ...data,
        pspSessionId: state.sessionDetails?.sessionId
      });
      if (response.status !== 'ok') {
        const errorCode =
          response.additionalDetails?.length &&
          response.additionalDetails[0].errorCode;

        console.error(
          'Failed to update payment session with response',
          response
        );
        setFatalError(
          errorCode ||
            (response.type === 'network'
              ? ErrorCode.PSPSessionUpdateFailedNetwork
              : response.status >= 400 && response.status < 500
              ? ErrorCode.PSPSessionUpdateFailedUnknown
              : ErrorCode.PSPSessionUpdateFailed),
          state.paymentFlowSessionId
        );
        return false;
      }
      return true;
    } catch (err) {
      console.error('Failed to update payment session with an error', err);
      setFatalError(
        ErrorCode.PSPSessionUpdateFailed,
        state.paymentFlowSessionId
      );
      return false;
    }
  };

  const makePayment = async (
    paymentMethod: any,
    component: DropinElement,
    recaptchaToken: string,
    storePaymentMethod?: boolean
  ) => {
    dispatch({
      type: 'payment'
    });

    if (
      !currentState.current.paymentFlowSessionId ||
      !currentState.current.sessionDetails?.sessionId
    ) {
      console.error('Payment flow session id or session id is missing');
      setFatalError(ErrorCode.PaymentFlowSessionIdMissing);
      return false;
    }

    try {
      const idempotencyKey = generateIdempotencyKey();
      datadogLogs.logger.info('PSP session and idemPotency key for request', {
        pspSessionId: currentState.current.sessionDetails.sessionId,
        idempotencyKey: idempotencyKey
      });

      const response = await ApiClient.getInstance(
        currentState.current.paymentFlowSessionId
      ).completePayment({
        pspSessionId: currentState.current.sessionDetails.sessionId,
        idempotencyKey,
        paymentMethod,
        recaptchaToken,
        storePaymentMethod
      });

      if (response.status !== 'ok') {
        const errorCode =
          response.additionalDetails?.length &&
          response.additionalDetails[0].errorCode;
        if (errorCode === ErrorCode.PaymentAmountTooLow) {
          showTemporaryErrorMessage(
            t('ERROR_FEE_AMOUNT_BELOW_MINIMUM'),
            component
          );
        } else if (errorCode === ErrorCode.ACHValidationFailed) {
          showTemporaryErrorMessage(
            t('ERROR_ACH_VALIDATION_FAILED'),
            component
          );
        } else if (errorCode === ErrorCode.BankAccountNotValid) {
          showTemporaryErrorMessage(
            t('ERROR_BANK_ACCOUNT_NOT_VALID'),
            component
          );
        } else if (errorCode === ErrorCode.DuplicateCompletePaymentAttempt) {
          showTemporaryErrorMessage(
            t('ERROR_DUPLICATE_PAYMENT_ATTEMPT'),
            component
          );
        } else if (errorCode === ErrorCode.RecaptchaBrowserError) {
          showTemporaryErrorMessage(t('RECAPTCHA_BROWSER_ERROR'), component);
        } else if (response.type === 'network') {
          console.error('Complete Payment Error Network (Checkout)', response);
          showUnknownErrorMessage(6, component);
        } else if (response.status >= 400 && response.status < 500) {
          console.error(
            'Complete Payment Error Unknown Bad Request (Checkout)',
            response
          );
          showUnknownErrorMessage(7, component);
        } else {
          console.error('Complete Payment Error Not OK (Checkout)', response);
          showUnknownErrorMessage(1, component);
        }
        return;
      }

      if (!response.data) {
        console.error('Complete Payment Error No Data (Checkout)', response);
        showUnknownErrorMessage(2, component);
        return;
      }

      if (['Authorised'].includes(response.data.resultCode)) {
        component.setStatus('success');
        acknowledgePayment(response.data.resultCode, paymentMethod);
        return;
      }

      if (['Error'].includes(response.data.resultCode)) {
        console.error('Complete Payment Error Result (Checkout)', response);
        showUnknownErrorMessage(3, component);
        return;
      }

      if (['Refused'].includes(response.data.resultCode)) {
        console.error('Complete Payment Error refused (Checkout)', response);
        const refusedCode = response.data.refusalReasonCode;
        let refusedMessage = response.data.refusalReason;

        if (refusedCode && MAP_REFUSED_CODE_TO_TRANSLATION_KEY[refusedCode]) {
          refusedMessage = t(MAP_REFUSED_CODE_TO_TRANSLATION_KEY[refusedCode]);
        }
        if (refusedMessage) showTemporaryErrorMessage(refusedMessage);
        else showUnknownErrorMessage(4, component);
        return;
      }

      component.setStatus('ready');
    } catch (err) {
      console.error('Complete Payment Error Unknown (Checkout)', err);
      showUnknownErrorMessage(5, component);
    }
  };

  const setComponentStatus = (
    status: 'success' | 'error' | 'loading' | 'ready',
    component: DropinElement
  ) => {
    component.setStatus(status);
  };

  const getReturnUrlWithParams = (
    returnUrl: string,
    resultCode: string,
    paymentMethod: any,
    additionalParams?: { name: string; value: string }[]
  ) => {
    if (!state.sessionDetails?.returnUrl) {
      return returnUrl;
    }

    let fee = '';
    let paymentMethodType: 'ach' | 'card' | null = null;

    if (paymentMethod.type === 'scheme') {
      fee = state.sessionDetails?.feeCredit?.toString() || '';
      paymentMethodType = 'card';
    }
    if (paymentMethod.type === 'ach') {
      fee = state.sessionDetails?.feeAch?.toString() || '';
      paymentMethodType = 'ach';
    }

    const params = new URLSearchParams({
      success: 'true',
      resultCode: resultCode,
      paymentMethod: paymentMethodType || '',
      amount: state.sessionDetails?.amount.toString(),
      fee: fee,
      currency: state.sessionDetails?.currency,
      referenceId: state.sessionDetails?.referenceId
    });

    const [urlWithoutQuery, returnUrlSearch] = returnUrl.split('?');

    if (returnUrlSearch) {
      const returnUrlParams = new URLSearchParams(returnUrlSearch);
      returnUrlParams.forEach((value, key) => {
        params.set(key, value);
      });
    }

    if (state.sessionDetails?.referenceData) {
      params.set('referenceData', state.sessionDetails.referenceData);
    }
    if (state.sessionDetails?.paymentAttributes) {
      const paymentAttributeString = JSON.stringify(
        state.sessionDetails.paymentAttributes
      );
      const base64 = Buffer.from(paymentAttributeString).toString('base64');
      params.set('paymentAttributes', base64);
    }
    additionalParams?.forEach((e) => {
      params.set(e.name, e.value);
    });
    return `${urlWithoutQuery}?${params.toString()}`;
  };

  const acknowledgePayment = (resultCode: string, paymentMethod: any) => {
    dispatch({
      type: 'paymentSuccess'
    });
    if (state.sessionDetails?.returnUrl) {
      let additionalParams = [];
      if (paymentMethod?.brand) {
        additionalParams.push({
          name: 'cardBrand',
          value: paymentMethod.brand
        });
      }
      const returnUrl = getReturnUrlWithParams(
        state.sessionDetails?.returnUrl,
        resultCode,
        paymentMethod,
        additionalParams
      );
      setTimeout(() => {
        window.location.href = String(returnUrl);
      }, 3000);
    } else if (state.sessionDetails?.appliedProductId === 'epic') {
      setTimeout(() => {
        window.close();
      }, 6000);
    }
  };

  const updatePaymentMethod = (paymentMethod: 'card' | 'ach' | null) => {
    dispatch({
      type: 'updatePaymentMethod',
      payload: { selectedPaymentMethod: paymentMethod }
    });
  };

  return {
    ...state,
    setFatalError,
    getSession,
    updateSession,
    initCheckout,
    makePayment,
    acknowledgePayment,
    setComponentStatus,
    updatePaymentMethod
  };
};

/*
  More refusals codes can be found here:
    https://docs.adyen.com/development-resources/refusal-reasons
    https://docs.adyen.com/development-resources/testing/result-code-testing/adyen-response-codes
*/
export const MAP_REFUSED_CODE_TO_TRANSLATION_KEY: Record<
  string,
  keyof Translation
> = {
  '0': 'REFUSED_ERROR_UNKNOWN',
  '6': 'REFUSED_ERROR_EXPIRED_CARD',
  '8': 'REFUSED_ERROR_INVALID_CARD_NUMBER',
  '20': 'REFUSED_ERROR_FRAUD',
  '22': 'REFUSED_ERROR_FRAUD',
  '24': 'REFUSED_ERROR_INVALID_CVC',
  '32': 'REFUSED_ERROR_BILLING_ADDRESS_MISMATCH'
};
