import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getConfig } from '../util/config';

export const useIsEligibleForFlowV2 = (
  tenantId: string | undefined,
  redirectTo: string
) => {
  const location = useLocation();
  const navigate = useNavigate();
  const enableV2Redirect = !location.search.includes('redirectV2=false');
  const pathIsV2 = location.pathname.includes('v2');

  const tenantIds =
    (getConfig('REACT_APP_ENABLE_HPP_CHECKOUT_NEW_UI') || '').split(' ') ?? [];
  const isEligible =
    (tenantId && tenantIds.includes(tenantId)) || tenantIds.includes('*');

  // Redirect to HPPV2
  useEffect(() => {
    if (tenantId === undefined) return; // Don't do anything until we're ready

    // If we're in V2 and we ARE NOT eligible OR
    // if we're in V1 and we ARE eligible AND we don't want to skip the redirect
    if (
      (pathIsV2 && !isEligible) ||
      (!pathIsV2 && isEligible && enableV2Redirect)
    ) {
      navigate(`${redirectTo}${location.search}${location.hash}`, {
        state: { from: location.pathname }
      });
    }
  }, [
    enableV2Redirect,
    isEligible,
    location,
    navigate,
    pathIsV2,
    redirectTo,
    tenantId
  ]);
};
