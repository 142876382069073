import {
  ADSChangeEvent,
  Checkbox
} from '@appliedsystems/applied-design-system';
import React from 'react';
import { usePaymentsTranslation } from '../../hooks/usePaymentsTranslation';

export const AcceptCCFeeCheckbox = ({
  onCheckboxChange,
  isChecked
}: {
  onCheckboxChange: (value: boolean) => void;
  isChecked: boolean;
}) => {
  const { t } = usePaymentsTranslation();
  const handleChange = (event: ADSChangeEvent) => {
    onCheckboxChange(event.target.value);
  };
  return (
    <Checkbox
      name="acceptFeesCheckbox"
      type="reverse"
      value={isChecked}
      onChange={handleChange}
    >
      {t('ACCEPT_CC_FEE')}
    </Checkbox>
  );
};
