import {
  Button,
  DataGrid,
  InlineAlert,
  SearchBox
} from '@appliedsystems/applied-design-system';
import { LocaleCode, toIntlFormat } from '@appliedsystems/payments-core';
import { map } from '@s-libs/micro-dash';
import { useQuery } from '@tanstack/react-query';
import { startOfDay, subDays, subYears } from 'date-fns';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import React, { useLayoutEffect, useMemo, useState } from 'react';
import { ApiClient } from '../../../api/ApiClient';
import { usePaymentsTranslation } from '../../../hooks/usePaymentsTranslation';
import { Translation } from '../../../localization/translations';
import { toLocaleDateString, toLocaleDateTimeString } from '../../../util/date';
import styles from './PaymentHistory.module.scss';

enum DateRangeKey {
  AllTime = 'AllTime',
  Last12Month = 'Last12Month',
  Last30Days = 'Last30Days',
  Last7Days = 'Last7Days'
}
const DateRangeName: { [_ in DateRangeKey]: keyof Translation } = {
  AllTime: 'ACCOUNT_SETTINGS_PH_DATEFILTER_ALL_TIME',
  Last12Month: 'ACCOUNT_SETTINGS_PH_DATEFILTER_LAST_12',
  Last30Days: 'ACCOUNT_SETTINGS_PH_DATEFILTER_LAST_30',
  Last7Days: 'ACCOUNT_SETTINGS_PH_DATEFILTER_LAST_7'
} as const;

// generates 8 undefined rows to display placeholder rows while loading
const LOADING_ROWS = Array.from({ length: 8 }, () => undefined);
const ACTION_CONTENT_HEIGHT = 52;

type Props = {
  locale?: LocaleCode;
};

export const PaymentHistory = ({ locale }: Props) => {
  const { t } = usePaymentsTranslation();
  const dataGridWrapperRef = React.useRef<HTMLDivElement>(null);
  const [viewMode, setViewMode] = useState(
    t(DateRangeName[DateRangeKey.AllTime])
  );
  const [search, setSearch] = useState('');
  const [dateRange, setDateRange] = useState<[Date, Date] | null>(null);
  const { data: rows, isLoading } = useQuery({
    queryKey: ['paymentHistory', dateRange],
    queryFn: async () => {
      const params: { [key: string]: string } = {};
      if (dateRange) {
        params['createdAt__gte'] = `${startOfDay(dateRange[0]).toISOString()}`;
      }

      const response = await ApiClient.getInstance().getPaymentHistory(params);
      if (response.status !== 'ok' || !response.data) {
        console.error(`Invalid response from server`, response);
        throw new Error(`Invalid response from server`);
      }

      return response.data.map((row) => ({
        ...row,
        date: toLocaleDateTimeString(new Date(row.date), '-') ?? '-',
        amount: toIntlFormat(
          {
            amount: row.amount,
            currencyCode: row.currency
          },
          locale ?? LocaleCode.en_US
        )
      }));
    },
    refetchInterval: 30 * 1000
  });

  const filteredRows = useMemo(() => {
    if (!rows) {
      return [];
    }

    if (!search) {
      return rows;
    }

    const searchTerm = search.trim().toLowerCase();

    return rows.filter((row) => {
      return (
        row.invoiceNumber?.toLowerCase().includes(searchTerm) ||
        row.description?.toLowerCase().includes(searchTerm) ||
        row.amount?.toLowerCase().includes(searchTerm) ||
        row.date?.toLowerCase().includes(searchTerm)
      );
    });
  }, [rows, search]);

  const handleDownload = () => {
    const workbook = new Workbook();
    const sheet = workbook.addWorksheet('Export');
    sheet.columns = [
      { header: t('ACCOUNT_SETTINGS_PH_GRID_DATE'), key: 'date', width: 10 },
      {
        header: t('ACCOUNT_SETTINGS_PH_GRID_INVOICE_NUMBER'),
        key: 'invoiceNumber',
        width: 10
      },
      {
        header: t('ACCOUNT_SETTINGS_PH_GRID_DESCRIPTION'),
        key: 'description',
        width: 32
      },
      { header: t('ACCOUNT_SETTINGS_PH_GRID_AMOUNT'), key: 'amount', width: 10 }
    ];
    (filteredRows ?? []).forEach((row) => {
      sheet.addRow({
        date: row.date,
        invoiceNumber: row.invoiceNumber,
        description: row.description,
        amount: row.amount
      });
    });
    const fileNameWithDates = dateRange
      ? `transactions-${toLocaleDateString(dateRange[0])}-${toLocaleDateString(
          dateRange[1]
        )}`
      : `transactions-${Date.now()}`;

    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      saveAs(blob, fileNameWithDates + '.xlsx');
    });
  };

  const viewModeOptions = useMemo(
    () =>
      map(DateRangeName, (nameKey, value) => ({
        text: t(nameKey),
        value
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onViewModeChange = (viewMode: string) => {
    setViewMode(viewMode);

    const dateRangeKey = viewModeOptions.find((mode) => mode.text === viewMode);

    if (!dateRangeKey) {
      console.error('Invalid date range key', dateRangeKey);
      return;
    }

    if (dateRangeKey.value === DateRangeKey.AllTime) {
      setDateRange(null);
      return;
    }

    const now = new Date();

    switch (dateRangeKey.value) {
      case DateRangeKey.Last12Month:
        setDateRange([subYears(now, 1), now]);
        break;
      case DateRangeKey.Last30Days:
        setDateRange([subDays(now, 30), now]);
        break;
      case DateRangeKey.Last7Days:
        setDateRange([subDays(now, 7), now]);
        break;
      default:
        throw new Error(`Invalid date range key ${dateRangeKey.value}`);
    }
  };

  const [dataGridHeight, setDataGridHeight] = useState(600);

  useLayoutEffect(() => {
    if (!dataGridWrapperRef.current) {
      return;
    }

    const rect = dataGridWrapperRef.current.getBoundingClientRect();
    const remaingHeight = window.innerHeight - rect.top - ACTION_CONTENT_HEIGHT;

    // min height of 400px
    setDataGridHeight(Math.max(remaingHeight, 400));
  }, []);

  return (
    <>
      <div className="mb-100">
        <InlineAlert>{t('ACCOUNT_SETTINGS_PH_NOTICE')}</InlineAlert>
      </div>

      <div className={styles.dataGrid} ref={dataGridWrapperRef}>
        <DataGrid
          columns={[
            {
              name: 'date',
              title: t('ACCOUNT_SETTINGS_PH_GRID_DATE')
            },
            {
              name: 'invoiceNumber',
              title: t('ACCOUNT_SETTINGS_PH_GRID_INVOICE_NUMBER')
            },
            {
              name: 'description',
              title: t('ACCOUNT_SETTINGS_PH_GRID_DESCRIPTION')
            },
            {
              name: 'amount',
              title: t('ACCOUNT_SETTINGS_PH_GRID_AMOUNT')
            }
          ]}
          rows={isLoading ? LOADING_ROWS : filteredRows ?? []}
          hideFooter
          viewModes={{
            enabled: true,
            options: map(DateRangeName, (nameKey, value) => ({
              text: t(nameKey)
            }))
          }}
          viewMode={viewMode}
          onViewModeChange={onViewModeChange}
          actionContent={
            <>
              {/*
                This is temporal workaround because we need to be able to download filtered rows
                and DataGrid search doesn't let us get current filtered rows
              */}
              <SearchBox
                placeholder={t('SEARCH')}
                value={search}
                onChange={setSearch}
              />
              <Button type="link" onClick={handleDownload}>
                {t('DOWNLOAD_TRANSACTIONS_HISTORY')}
              </Button>
            </>
          }
          maxHeight={dataGridHeight}
        />
      </div>
    </>
  );
};
