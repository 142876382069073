import {
  BrandType,
  Col,
  Container,
  InlineAlert,
  Row
} from '@appliedsystems/applied-design-system';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { usePaymentsTranslation } from '../../hooks/usePaymentsTranslation';
import { Translation } from '../../localization/translations';
import { ErrorDetail } from '../../types/common';
import classes from './Error.module.scss';

/*
  location.state: {
    errorCode: keyof Translation - the error message to display
    errorDetail: Dict | string
      Dict - each key is a pattern which will be surrounded with <> and used to find+replace in the translated errorCode, the value is what to replace it with
      string - a raw string to display after the translated errorCode
  }
  */
export const Error = (): React.ReactElement => {
  const location = useLocation();
  const { t } = usePaymentsTranslation();

  const errorCode =
    (location.state as { errorCode: keyof Translation })?.errorCode ||
    'ERROR_GENERIC';
  const translatedErrorCode = t(errorCode);

  const errorDetail =
    (location.state as { errorDetail: ErrorDetail })?.errorDetail || '';

  const errorDetailString = typeof errorDetail === 'string' ? errorDetail : '';
  const errorDetailObject = typeof errorDetail === 'object' ? errorDetail : {};

  const replacedTranslatedErrorCode = Object.entries(errorDetailObject).reduce(
    (str, [pattern, value]) => str.replace(`<${pattern}>`, `${value}`),
    translatedErrorCode
  );

  const isInitAdyenCheckoutFailed = errorCode === 'INIT_ADYEN_CHECKOUT_FAILED';

  return (
    <>
      <div className={classes.app}>
        <Container>
          <Row>
            <Col xs={12}>
              <InlineAlert closeable={false} type={BrandType.Error}>
                {isInitAdyenCheckoutFailed ? (
                  <>
                    {t('ERROR_PAYMENT_INITIATION')}
                    <p>{t('ERROR_TRY')}</p>
                    <ul>
                      <li>{t('ERROR_CHECK_INTERNET')}</li>
                      <li>{t('ERROR_CLEAR_CACHE')}</li>
                      <li>{t('ERROR_TURN_OFF_FIREWALL')}</li>
                    </ul>
                    <p>{t('ERROR_CONTACT_IT')}</p>
                  </>
                ) : (
                  <>
                    {replacedTranslatedErrorCode} {errorDetailString}
                  </>
                )}
              </InlineAlert>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
