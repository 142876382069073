import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { createContainer } from 'unstated-next';
import { ApiClient } from '../api/ApiClient';
import { DefaultBrandTheme } from '../styles/createBrandTheme';

const useBrandThemeStore = () => {
  const [requestToken, setRequestToken] = useState<string | null>(null);
  const { data, isLoading } = useQuery({
    queryKey: [requestToken],
    queryFn: async () => {
      if (!requestToken) {
        return DefaultBrandTheme;
      }

      const response = await ApiClient.getInstance(
        requestToken
      ).getPaymentBrandSettings();

      if (response.status !== 'ok') {
        console.error('Failed to get brand theme', response);
        throw new Error('Failed to get brand theme');
      }

      return response.data;
    }
  });

  return { theme: data, isLoading, setRequestToken };
};

export const BrandTheme = createContainer(useBrandThemeStore);

export const useBrandTheme = (requestToken?: string | null) => {
  const { theme, isLoading, setRequestToken } = BrandTheme.useContainer();

  useEffect(() => {
    setRequestToken(requestToken ?? null);
  }, [setRequestToken, requestToken]);

  return { theme, isLoading, setRequestToken };
};
