import {
  Button,
  Col,
  Flex,
  H1,
  Row
} from '@appliedsystems/applied-design-system';

import React from 'react';
import { currencyMap } from '../../../src/constants/constants';
import { Locale } from '../../../src/store/Locale';
import { toIntlFormatHelper } from '../../../src/util/getAmountWithFees';
import { usePaymentsTranslation } from '../../hooks/usePaymentsTranslation';
import { useHppDataStore } from '../HostedPaymentPageContainer/useHppData';
import { SummaryCard } from '../SummaryCard/SummaryCard';
import greenCheck from './check-circle.svg';
import classes from './ConfirmationPage.module.scss';

export const ConfirmationPage = ({
  merchantName
}: {
  merchantName: string | undefined | null;
}) => {
  const { t } = usePaymentsTranslation();

  const printConfirmation = () => {
    // Save the original title and set the new title to the merchant name for saving pdf in merchant name
    const originalTitle = document.title;
    document.title = merchantName || t('PRINT_CONFIRMATION');
    window.print();
    document.title = originalTitle;
  };

  const { locale } = Locale.useContainer();
  const { hppData } = useHppDataStore();

  return (
    <Flex>
      <Row>
        <Col xs={12}>
          <div className={classes.successIcon}>
            <img src={greenCheck} alt={t('CONFIRMATION_SUCCESS_ALT_TEXT')} />
          </div>
          <Flex className="flex-d-column">
            <H1 className="text-center">
              {t('PAYMENT_CONFIRMATION', undefined, {
                totalAmount: toIntlFormatHelper(
                  hppData.paymentTotal,
                  currencyMap[locale],
                  locale
                )
              } as any)}
            </H1>
            <p className="text-center">
              {t('PAYMENT_CONFIRMATION_DESCRIPTION', undefined, {
                email: hppData.userEmail
              } as any)}
            </p>
          </Flex>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Flex className="flex-align-center">
            <Flex className="flex-item maxw-480">
              <SummaryCard isConfirmation={true} />
            </Flex>
          </Flex>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className={classes.printButton}>
          <Button
            onClick={() => printConfirmation()}
            aria-label={t('PRINT_CONFIRMATION')}
            className="brandPrimaryButton"
            type="primary"
          >
            {t('PRINT_CONFIRMATION')}
          </Button>
        </Col>
      </Row>
    </Flex>
  );
};
