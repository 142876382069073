import {
  Button,
  Form,
  ModalBody,
  ModalFooter,
  object,
  string,
  TextField
} from '@appliedsystems/applied-design-system';
import { removeNullProperties } from '@appliedsystems/payments-core';
import React, { useMemo, useState } from 'react';
import { usePaymentsTranslation } from '../../../hooks/usePaymentsTranslation';
import { Translation } from '../../../localization/translations';
import styles from '../AccountManagementModals.module.scss';

const makeSchema = (t: (key: keyof Translation) => string) =>
  object({
    firstName: string().required(t('ERROR_FIRST_NAME_REQUIRED')),
    lastName: string().required(t('ERROR_LAST_NAME_REQUIRED')),
    email: string()
      .email(t('ERROR_EMAIL_INVALID'))
      .required(t('ERROR_EMAIL_REQUIRED')),
    accountNumber: string()
  });

export type CreateAccountFormData = {
  firstName: string;
  lastName: string;
  email: string;
  accountNumber: string;
};

type Props = {
  onSubmit: (formData: CreateAccountFormData) => Promise<void>;
  onCancel: () => void;
  onLoginClick: () => void;
};

export const CreateAccountForm = ({
  onCancel,
  onSubmit,
  onLoginClick
}: Props) => {
  const { t } = usePaymentsTranslation();
  const [loading, setLoading] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const schema = useMemo(() => makeSchema(t), []);

  const handleSubmit = async (values: CreateAccountFormData) => {
    setLoading(true);

    await onSubmit(removeNullProperties(values) as CreateAccountFormData);

    setLoading(false);
  };

  const handleError = (errors: any) => {
    console.warn('Failed to submit the create account form', errors);
  };

  return (
    <Form schema={schema} onSubmit={handleSubmit} onSubmitFailure={handleError}>
      <ModalBody>
        <div className={styles.modalBody}>
          <p>
            {t('ALREADY_HAVE_ACCOUNT')}{' '}
            <Button type="link" onClick={onLoginClick}>
              {t('LOG_IN')}
            </Button>
          </p>

          <TextField
            name="firstName"
            label={t('FIRST_NAME')}
            className="mb-100"
          />
          <TextField
            name="lastName"
            label={t('LAST_NAME')}
            className="mb-100"
          />
          <TextField name="email" label={t('EMAIL')} className="mb-100" />
          <TextField name="accountNumber" label={t('ACCOUNT_NUMBER')} />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button type="tertiary" onClick={onCancel}>
          {t('CANCEL')}
        </Button>
        <Button
          testId="createAccountButton"
          type="primary"
          className="ml-100"
          submit
          isLoading={loading}
        >
          {t('CREATE_ACCOUNT')}
        </Button>
      </ModalFooter>
    </Form>
  );
};
