import {
  Button,
  FieldSkeleton,
  Flex,
  Form,
  number,
  object,
  string
} from '@appliedsystems/applied-design-system';
import React, { useMemo } from 'react';
import { usePaymentsTranslation } from '../../hooks/usePaymentsTranslation';
import { useAgencyDetailsStore } from '../../store/AgencyDetail';
import { ErrorAlert } from '../ErrorAlert/ErrorAlert';
import { PayBySelection } from '../HostedPaymentPageContainer/HostedPaymentPageContainer';
import { HppData } from '../HostedPaymentPageContainer/types';
import { useHppDataStore } from '../HostedPaymentPageContainer/useHppData';
import { useHppSessionStore } from '../HostedPaymentPageContainer/useHppSession';
import { PolicyFormFields } from './PolicyInformationFormFields';

export type PolicyFormSchema = Pick<
  HppData,
  | 'paymentAmount'
  | 'paymentDescription'
  | 'policyNumber'
  | 'invoiceNumber'
  | 'payBy'
>;

export const PolicyInformationForm = ({
  onDataValidated,
  onDataChange,
  onBack
}: {
  onDataValidated: () => void;
  onDataChange: () => void;
  onBack: () => void;
}) => {
  // Hooks
  const { t } = usePaymentsTranslation();
  const {
    initializeHppPaymentSession,
    errorMessage: hppSessionErrorMessage,
    isSubmitting
  } = useHppSessionStore();
  const { data: agencyDetails } = useAgencyDetailsStore();

  // Store
  const { hppData, selectedInvoices, retrievedInvoices } = useHppDataStore();
  const { payBy } = hppData;

  const formSchema = useMemo(
    () =>
      object<PolicyFormSchema>({
        payBy: string()
          .oneOf(Object.values(PayBySelection))
          .uppercase()
          .required(),
        // Pay by amount
        paymentAmount: number()
          .label(t('AMOUNT'))
          .min(1)
          .max(1200000)
          .transform(
            (_, originalValue) =>
              originalValue === Number(originalValue)
                ? originalValue
                : undefined // null and undefined both return a null value
          )
          .when('payBy', {
            is: (payBy: PayBySelection) =>
              payBy === PayBySelection.AMOUNT || payBy === PayBySelection.NONE,
            then: (schema) => schema.required(),
            otherwise: (schema) => schema.optional()
          }),
        paymentDescription: string()
          .label(t('DESCRIPTION'))
          .when('payBy', {
            is: (payBy: PayBySelection) => payBy !== PayBySelection.INVOICE,
            then: (schema) => schema.required(),
            otherwise: (schema) => schema.optional()
          }),
        policyNumber: string().label(t('POLICY_NUMBER')).optional(),
        invoiceNumber: string().label(t('INVOICE_NUMBER')).optional()
      }).default({
        payBy: payBy
      }),
    [payBy, t]
  );

  const handleFailedSubmission = () => {
    onDataChange();
  };

  // Callbacks
  const handleSuccessfulSubmission = async (values: PolicyFormSchema) => {
    if (
      !(await initializeHppPaymentSession(
        agencyDetails!.token,
        hppData,
        retrievedInvoices,
        selectedInvoices
      ))
    )
      return;

    onDataValidated();
  };

  return (
    <>
      <Form
        debug={false}
        schema={formSchema}
        onSubmit={handleSuccessfulSubmission}
        onSubmitFailure={handleFailedSubmission}
      >
        {isSubmitting ? (
          <FieldSkeleton />
        ) : (
          <>
            <PolicyFormFields onDataChange={onDataChange} />

            {!!hppSessionErrorMessage && (
              <ErrorAlert
                errorMessage={hppSessionErrorMessage}
                className="mt-150"
              />
            )}

            <Flex className="flex-align-end mt-150">
              <Button type="tertiary" onClick={onBack}>
                {t('BACK')}
              </Button>
              <Button
                className="brandPrimaryButton"
                type="primary"
                submit
                testId="continue-to-payment"
                disabled={
                  (payBy === PayBySelection.INVOICE &&
                    selectedInvoices.length === 0) ||
                  isSubmitting
                }
                isLoading={isSubmitting}
              >
                {t('CONTINUE_TO_PAYMENT')}
              </Button>
            </Flex>
          </>
        )}
      </Form>
    </>
  );
};
