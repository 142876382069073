import { HppConfig } from '@appliedsystems/payments-core';
import { Buffer } from 'buffer';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useURLQuery } from './useURLQuery';

export const useHppToken = () => {
  const location = useLocation();
  const query = useURLQuery();

  const [hppToken, setHppToken] = React.useState<string | null>(
    () => query.get('token') || window.localStorage.getItem('hppToken')
  );

  const [hppConfig, setHppConfig] = React.useState<string | null>(
    () => query.get('c') || window.localStorage.getItem('hppConfig')
  );

  const parsedHppConfig = React.useMemo<HppConfig>(() => {
    const defaultValues = {
      showLogo: true,
      showTitle: true,
      showCopyright: true
    };
    if (!hppConfig) {
      return defaultValues;
    }

    try {
      const plainText = Buffer.from(hppConfig, 'base64').toString();
      return { ...defaultValues, ...JSON.parse(plainText) };
    } catch (e) {
      return defaultValues;
    }
  }, [hppConfig]);

  useEffect(() => {
    if (!hppToken) {
      window.localStorage.removeItem('hppToken');
      window.localStorage.removeItem('hppConfig');
      return;
    }
    window.localStorage.setItem('hppToken', hppToken);
    if (hppConfig) {
      window.localStorage.setItem('hppConfig', hppConfig);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, hppToken]);

  const clearSession = () => {
    setHppToken(null);
    setHppConfig(null);
  };

  return { hppToken, hppConfig: parsedHppConfig, clearSession };
};
