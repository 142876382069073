import { datadogLogs } from '@datadog/browser-logs';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useAccountManagementStore } from '../store/AccountManagement';
import { getConfig } from '../util/config';
import { useFeatureFlags } from './useFeatureFlag';

export const useAccountManagement = (
  appliedProductId?: string,
  paymentSessionToken?: string | null
) => {
  const accountManagementStore = useAccountManagementStore();
  const [isAccountManagementEnabled, setIsAccountManagementEnabled] =
    useState(false);
  const { getCurrentCustomerUser, logout, customerUser } =
    accountManagementStore;
  const { isAccountManagementEnabled: isAccountManagementEnabledFF } =
    useFeatureFlags();

  // Check if the user is authenticated every 2 minutes
  const { isLoading: isCustomerUserLoading } = useQuery({
    queryKey: ['isAuthenticated'],
    refetchInterval: () => (customerUser ? 1000 * 60 * 2 : false), // 1 minute,
    refetchOnWindowFocus: () => !!customerUser,
    queryFn: async () => {
      if (!paymentSessionToken) {
        return false;
      }

      const response = await getCurrentCustomerUser(paymentSessionToken);
      if (response.status !== 'ok') {
        logout();
      }

      return response.status === 'ok';
    }
  });

  useEffect(() => {
    const ACCOUNT_MANAGEMENT_FEATURE_FLAG_ENABLED = getConfig(
      'REACT_APP_ACCOUNT_MANAGEMENT_ENABLED'
    );

    setIsAccountManagementEnabled(
      ACCOUNT_MANAGEMENT_FEATURE_FLAG_ENABLED === 'true' &&
        appliedProductId !== 'ezl'
    );
  }, [appliedProductId]);
  if (isAccountManagementEnabledFF !== isAccountManagementEnabled)
    datadogLogs.logger.warn(
      `Feature flag service returned ${isAccountManagementEnabledFF} for AccountManagement but should have been ${isAccountManagementEnabled}`
    );
  useEffect(() => {
    if (!isAccountManagementEnabled || !paymentSessionToken) {
      return;
    }

    const checkCurrentCustomerUserAuth = async () => {
      try {
        const response = await getCurrentCustomerUser(paymentSessionToken);

        if (response.status !== 'ok') {
          logout();
        }
      } catch (error) {
        console.error(
          'Failed to check current customer user auth with an error',
          error
        );
      }
    };

    checkCurrentCustomerUserAuth();
  }, [
    paymentSessionToken,
    isAccountManagementEnabled,
    getCurrentCustomerUser,
    logout
  ]);

  return {
    ...accountManagementStore,

    isAccountManagementEnabled,
    customerUser,
    isCustomerUserLoading
  };
};
